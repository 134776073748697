<template>
  <v-dialog
    data-testid="contract-creation-turn-into-employee-dialog"
    :value="true"
    :retain-focus="false"
    persistent
    width="750"
  >
    <v-card class="py-6 px-4">
      <v-card-title
        data-testid="contract-creation-turn-into-employee-title"
        class="heading-h3 mb-4"
      >
        {{ handleTitle }}
      </v-card-title>
      <v-card-text class="mineBlack--text d-flex flex-column">
        <span
          data-testid="contract-creation-turn-into-employee-message"
          class="body-text-3 mb-6"
        >
          {{ t('Action_.dialog.turnIntoPendingEmployeeDialog.body') }}
        </span>
        <span
          data-testid="contract-creation-turn-into-employee-reinforcement"
          class="body-text-3"
        >
          {{ t('Action_.dialog.turnIntoPendingEmployeeDialog.reinforcement') }}
        </span>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          data-testid="contract-creation-turn-into-employee-cancel-btn"
          class="main-action-variant-btn"
          @click="toggleTurnIntoEmployeeDialog()"
        >
          {{ t('Action_.buttons.cancel') }}
        </v-btn>
        <v-btn
          data-testid="contract-creation-turn-into-employee-create-employee-btn"
          class="main-action-btn ml-2"
          @click="createEmployee()"
        >
          {{ t('Action_.buttons.sendContractViaEversign') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import languages from '../../../locales/languages';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { computed, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n-bridge';

const peopleContractCreationStore = usePeopleContractCreationStore();

const emit = defineEmits([
  'send-pending-employee-contract',
  'toggle-turn-into-pending-employee-dialog',
]);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const toggleTurnIntoEmployeeDialog = () => {
  emit('toggle-turn-into-pending-employee-dialog');
};

const createEmployee = () => {
  emit('send-pending-employee-contract');
};

// --- Computed ---
const employeeName = computed(() => {
  return `${peopleContractCreationStore.careOpsContract.firstName} ${peopleContractCreationStore.careOpsContract.lastName}`;
});

const handleTitle = computed(() => {
  return `${t('Action_.dialog.turnIntoPendingEmployeeDialog.title1')} ${
    employeeName.value
  } ${t('Action_.dialog.turnIntoPendingEmployeeDialog.title2')}`;
});
</script>

<style lang="scss" scoped></style>
