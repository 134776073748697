<template>
  <div>
    <ContractCreationCareOpsMasterInformation class="mb-8" />
    <ContractCreationCareOpsPayrollInformation class="mb-8" />
    <ContractCreationCareOpsContractInformation />
    <v-container class="mt-4">
      <v-row class="d-flex justify-end">
        <v-col cols="auto" class="px-0">
          <v-btn
            v-if="isCreatePendingEmployee"
            data-testid="contract-creation-care-ops-create-pending-employee-btn"
            class="main-action-btn mb-2"
            :disabled="disableCreatePendingEmployeeBtn"
            @click="toggleTurnIntoEmployeeDialog()"
          >
            {{ t('Action_.buttons.createEmployee') }}
          </v-btn>
          <v-btn
            v-else-if="isEditing"
            data-testid="contract-creation-care-ops-save-changes-btn"
            class="main-action-btn mb-2"
            :disabled="disableCreatePendingEmployeeBtn"
            @click="updateEmployee()"
          >
            {{ t('Action_.buttons.saveChanges') }}
          </v-btn>
          <v-btn
            v-else-if="
              peopleContractCreationStore.careOpsContract.generatedContractId
            "
            data-testid="contract-creation-care-ops-send-contract-via-eversign-btn"
            class="main-action-btn mb-2"
            :disabled="!isFormValid"
            @click="toggleTurnIntoPendingEmployeeDialog()"
          >
            {{ t('Action_.buttons.sendContractViaEversign') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <ContractCreationTurnIntoEmployeeDialog
      v-if="peopleContractCreationStore.displayTurnIntoEmployeeDialog"
      :contract="peopleContractCreationStore.careOpsContract"
      @toggle-turn-into-employee-dialog="toggleTurnIntoEmployeeDialog()"
      @create-employee="createEmployee()"
    />
    <ContractCreationTurnIntoPendingEmployeeDialog
      v-if="peopleContractCreationStore.displayTurnIntoPendingEmployeeDialog"
      @toggle-turn-into-pending-employee-dialog="
        toggleTurnIntoPendingEmployeeDialog()
      "
      @send-pending-employee-contract="createEmployee()"
    />
  </div>
</template>

<script setup>
import ContractCreationCareOpsMasterInformation from './contract-creation-care-ops-master-information.vue';
import ContractCreationCareOpsPayrollInformation from './contract-creation-care-ops-payroll-information.vue';
import ContractCreationCareOpsContractInformation from './contract-creation-care-ops-contract-information.vue';
import ContractCreationTurnIntoEmployeeDialog from '@/components/people/contracts/contractCreation/contractCreationDialogs/contract-creation-turn-into-employee-dialog.vue';
import ContractCreationTurnIntoPendingEmployeeDialog from '@/components/people/contracts/contractCreation/contractCreationDialogs/contract-creation-turn-into-pending-employee-dialog.vue';

import languages from '@/components/people/locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { useRoute, useRouter } from 'vue-router/composables';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { computed, onMounted } from 'vue';
import { useContractToolsV2 } from '@/helpers/composables/contract-tools-v2.js';

const route = useRoute();
const router = useRouter();
const { trackPage } = amplitudeComposable();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t } = useI18n({ locale: 'en', messages: languages });
const {
  totalActiveSwitches,
  prepareWeeklyWorkingHoursToPost,
  formatAdditionalTrainingKeysToPost,
  formatCurrencyFieldsToPost,
  formatPercentageFieldsToPost,
} = useContractToolsV2();

// --- Local variables ---
const currencyKeys = [
  'hourlyWage',
  'cityBonus',
  'careAllowance',
  'nonDynamicAllowance',
  'individualAllowance',
  'monthlyBaseSalary',
  'thirteenthSalary',
  'annualSalary',
];

const percentageKeys = [
  'costCenter1Percentage',
  'costCenter2Percentage',
  'costCenter3Percentage',
];

// --- Methods ---
const updateContractCreationData = () => {
  let payload = prepareDataToPost();
  try {
    peopleContractCreationStore.updateContractCreationData(payload);
  } catch (error) {
    console.log(error);
  }
};

const postContractCreationData = () => {
  let payload = prepareDataToPost();
  try {
    peopleContractCreationStore.postContractCreationData(payload);
  } catch (error) {
    console.log(error);
  }
};

const redirectUser = () => {
  if (peopleContractCreationStore.careOpsContract.uploadedContract) {
    router.push({
      name: 'EmployeeList',
    });
  } else {
    router.push({
      name: 'PendingEmployeesList',
    });
  }
};

const updateEmployee = () => {
  updateContractCreationData();
  redirectUser();
};

const createEmployee = () => {
  postContractCreationData();
  redirectUser();
};

const prepareDataToPost = () => {
  let payload = { ...{}, ...peopleContractCreationStore.careOpsContract };

  payload.weeklyWorkingHours = prepareWeeklyWorkingHoursToPost(
    peopleContractCreationStore.careOpsContract.weeklyWorkingHours
  );

  formatCurrencyFieldsToPost(currencyKeys, payload);

  formatPercentageFieldsToPost(percentageKeys, payload);

  payload.additionalTraining = formatAdditionalTrainingKeysToPost(
    payload.additionalTraining
  );

  payload.yearsOfExperience = parseInt(payload.yearsOfExperience);
  payload.totalWeeklyWorkingDays = totalActiveSwitches(
    peopleContractCreationStore.careOpsContract
  );

  if (!payload.startDatePriorToAcquisition) {
    // this field needs to be null if not populated because the BE needs to receive a null value instead of an empty string
    payload.startDatePriorToAcquisition = null;
  }

  return payload;
};

const toggleTurnIntoEmployeeDialog = () => {
  peopleContractCreationStore.displayTurnIntoEmployeeDialog =
    !peopleContractCreationStore.displayTurnIntoEmployeeDialog;
};

const toggleTurnIntoPendingEmployeeDialog = () => {
  peopleContractCreationStore.displayTurnIntoPendingEmployeeDialog =
    !peopleContractCreationStore.displayTurnIntoPendingEmployeeDialog;
};

// --- Computed ---
const isEditing = computed(() => {
  return !!route.params.employeeId;
});

const isFormValid = computed(() => {
  return Object.values(peopleContractCreationStore.formValidations).every(
    (value) => value === true
  );
});

const controlDisplayInfo = computed(() => {
  let display = 'buttons';
  if (peopleContractCreationStore.careOpsContract.uploadedContract) {
    display = 'uploadedContract';
  }
  if (peopleContractCreationStore.careOpsContract.generatedContractId) {
    display = 'generatedContract';
  }
  return display;
});

const disableCreatePendingEmployeeBtn = computed(() => {
  return !isFormValid.value || controlDisplayInfo.value === 'buttons';
});

const isCreatePendingEmployee = computed(() => {
  return (
    (peopleContractCreationStore.careOpsContract.uploadedContract ||
      (!peopleContractCreationStore.careOpsContract.generatedContractId &&
        !peopleContractCreationStore.careOpsContract.uploadedContract)) &&
    !isEditing.value
  );
});

// --- Lifecycle hooks ---
onMounted(() => {
  trackPage();
});
</script>

<style scoped lang="scss"></style>
