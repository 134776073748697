export const useDocumentTools = () => {
  const retryRequest = async (request, time) => {
    for (let i = 0; i < 10; i++) {
      try {
        const response = await request();
        if (response.documentPath) return response;
        await new Promise((resolve) => setTimeout(resolve, time));
      } catch (error) {
        console.log(error);
      }
    }
    throw new Error('Retry request max attempts failed.');
  };

  const displayDocument = (endpoint, path) => {
    window.open(`${process.env.VUE_APP_LOGIN_API}${endpoint}${path}`, '_blank');
  };

  return { retryRequest, displayDocument };
};
