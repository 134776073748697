var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{staticClass:"shadow-regular",attrs:{"data-testid":"contract-creation-headquarter-contract-info-container","value":_setup.open,"flat":""}},[_c('v-expansion-panel',{staticClass:"rounded-lg pa-2"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"data-testid":"contract-creation-headquarter-contract-info-chevron-icon","color":"#212121"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}])},[_c('div',[_c('span',{staticClass:"heading-h3 mr-2",attrs:{"data-testid":"contract-creation-headquarter-contract-info-title"}},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.contractInformation'))+" ")])])]),_c('v-expansion-panel-content',[_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"data-testid":"contract-creation-headquarter-contract-info-form"},model:{value:(
          _setup.peopleContractCreationStore.formValidations.contractInfoValidation
        ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.formValidations, "contractInfoValidation", $$v)},expression:"\n          peopleContractCreationStore.formValidations.contractInfoValidation\n        "}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-start-date-picker","label":_setup.t('Msg_.startDateAtKenbi'),"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.startDateAtKenbi')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.startDate
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "startDate", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.startDate\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-job-title-dropdown","label":_setup.t('Msg_.jobTitle'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectJobTitle,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.jobTitle')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ],"disabled":""},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.jobPosition
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "jobPosition", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.jobPosition\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-job-subtitle-field","label":_setup.t('Msg_.jobSubtitle'),"optional":"","rules":[
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.jobSubtitle')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.jobSubtitle
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "jobSubtitle", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.jobSubtitle\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-main-hub-dropdown","label":_setup.t('Msg_.mainHub'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.profileStore.profileHubs,"item-value":"id","item-text":"name","clearable":false,"value":_setup.peopleContractCreationStore.headquarterContract.mainHub,"disabled":""}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-main-hub-dropdown","label":_setup.t('Msg_.company'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.companies,"item-value":"id","item-text":"name","clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.company')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.company
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "company", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.company\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-workplace-field","label":_setup.t('Msg_.workplace'),"rules":[
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.workplace')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.workplace
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "workplace", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.workplace\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-contract-type-dropdown","label":_setup.t('Msg_.contractType'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectContractType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.contractType')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.contractType
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "contractType", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.contractType\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-compensation-type-field","disabled":"","label":_setup.t('Msg_.compensationType'),"value":_setup.getCompensationTypeText}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-weekly-working-hours-field","label":_setup.t('Msg_.weeklyWorkingHours'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.weeklyWorkingHours')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                  _setup.rules.numberWithDecimals(_setup.t('Msg_.rules.onlyNumbers')),
                  (v) =>
                    _setup.isWeeklyWorkingHoursValid ||
                    _setup.t('Msg_.rules.maximumValueExceeded'),
                ]},on:{"change":function($event){return _setup.handleTotalWeeklyWorkingHours()}},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract
                    .totalWeeklyWorkingHours
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract
                    , "totalWeeklyWorkingHours", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract\n                    .totalWeeklyWorkingHours\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-vacation-days-field","disabled":"","label":_setup.t('Msg_.vacationDays'),"value":_setup.vacationDaysPerYear,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.vacationDays')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('span',{staticClass:"custom-label pl-3 mb-1"},[_vm._v(_vm._s(_setup.t('Msg_.workingDays')))]),_c('v-col',{staticClass:"d-flex px-0 pt-0",attrs:{"cols":"12"}},_vm._l((_setup.peopleContractCreationStore
                  .headquarterContract.weeklyWorkingHours),function(item,index){return _c('div',{key:index,staticClass:"d-flex flex-column flex-grow-1"},[_c(_setup.SwitchControl,{staticClass:"my-2",attrs:{"data-testid":`contract-creation-headquarter-contract-info-week-day-${index}-switch`,"value":item.isActive,"hide-details":""},on:{"click":function($event){return _setup.updateDailyWorkingHours(index)}}}),_c('span',{staticClass:"pl-3 custom-label"},[_vm._v(_vm._s(_setup.tm(`Action_.selectWeekDays`)[index]))]),_c('span',{staticClass:"pl-3 custom-label",class:item.isActive ? 'mineBlack--text' : 'greyRegular--text'},[_vm._v(" "+_vm._s(item.hours)+" "+_vm._s(_setup.t('Msg_.hours'))+" ")])],1)}),0)],1),_c('v-row',{staticClass:"my-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-contract-period-dropdown","label":_setup.t('Msg_.contractPeriod'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectContractPeriodType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.contractPeriod')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract
                    .contractPeriod
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract
                    , "contractPeriod", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract\n                    .contractPeriod\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-exit-date-picker","label":_setup.t('Msg_.exitDate'),"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":_setup.exitDateRules,"disabled":_setup.isExitDateDisabled},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.exitDate
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "exitDate", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.exitDate\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-probation-dropdown","label":_setup.t('Msg_.probation'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectProbationType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.probation')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.probation
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "probation", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.probation\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-notice-period-during-probation-dropdown","label":_setup.t('Msg_.noticePeriodDuringProbation'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectProbationNoticePeriodType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.noticePeriodDuringProbation')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`,
                    0
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract
                    .noticePeriodDuringProbation
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract
                    , "noticePeriodDuringProbation", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract\n                    .noticePeriodDuringProbation\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-notice-period-after-probation-dropdown","label":_setup.t('Msg_.noticePeriodAfterProbation'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectProbationNoticePeriodAfterType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.noticePeriodAfterProbation')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`,
                    0
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract
                    .noticePeriodAfterProbation
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract
                    , "noticePeriodAfterProbation", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract\n                    .noticePeriodAfterProbation\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-monthly-base-salary-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.monthlyBaseSalary'),"value":_setup.peopleContractCreationStore.headquarterContract
                    .monthlyBaseSalary,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.monthlyBaseSalary')} ${_setup.t('Msg_.isRequired')}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.updateCurrencyField(
                    'monthlyBaseSalary',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.updateCurrencyField(
                    'monthlyBaseSalary',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-annual-salary-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.annualSalary'),"value":_setup.peopleContractCreationStore.headquarterContract.annualSalary,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.annualSalary')} ${_setup.t('Msg_.isRequired')}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.updateCurrencyField(
                    'annualSalary',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.updateCurrencyField(
                    'annualSalary',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-bonus-target-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.bonusTarget'),"value":_setup.peopleContractCreationStore.headquarterContract.bonusTarget},on:{"change":function($event){_setup.updateCurrencyField(
                    'bonusTarget',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.updateCurrencyField(
                    'bonusTarget',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-contract-info-assigner-field","disabled":"","label":_setup.t('Msg_.assigner'),"value":_setup.userStore.userInfo.name}})],1)],1),_c('v-row',{staticClass:"my-5",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('span',{staticClass:"custom-label mb-4"},[_vm._v(" "+_vm._s(_setup.t('Msg_.contract'))+" ")]),(_setup.controlDisplayInfo === 'buttons')?_c('div',[_c('v-btn',{staticClass:"main-action-variant-transparent-btn mr-4",attrs:{"data-testid":"contract-creation-headquarter-contract-info-upload-contract-btn"},on:{"click":function($event){return _setup.triggerFileSelector()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.uploadContract'))+" ")]),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileSelector",attrs:{"type":"file","accept":".pdf"},on:{"change":function($event){return _setup.postDocument('uploadedContract', $event)}}})],1):(_setup.controlDisplayInfo === 'uploadedContract')?_c('div',[_c(_setup.FileInput,{attrs:{"deletable":"","data-testid":"contract-creation-headquarter-uploaded-contract-file-input","label":_setup.t('Msg_.uploadedContract'),"value":_setup.peopleContractCreationStore.headquarterContract
                      .uploadedContract},on:{"display-document":function($event){return _setup.displayDocument('uploadedContract')},"delete-document":function($event){return _setup.deleteDocument('uploadedContract')}}})],1):_vm._e()])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }