<template>
  <div>
    <ContractCreationHeadquarterMasterInformation class="mb-8" />
    <ContractCreationHeadquarterPayrollInformation class="mb-8" />
    <ContractCreationHeadQuarterContractInformation />
    <v-container class="mt-4">
      <v-row class="d-flex justify-end">
        <v-col cols="auto" class="px-0">
          <v-btn
            data-testid="contract-creation-headquarter-create-pending-employee-btn"
            class="main-action-btn mb-2"
            :disabled="disableCreateEmployeeBtn"
            @click="toggleTurnIntoEmployeeDialog()"
          >
            {{ t('Action_.buttons.createEmployee') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <ContractCreationTurnIntoEmployeeDialog
      v-if="peopleContractCreationStore.displayTurnIntoEmployeeDialog"
      :contract="peopleContractCreationStore.headquarterContract"
      @toggle-turn-into-employee-dialog="toggleTurnIntoEmployeeDialog()"
      @create-employee="createEmployee()"
    />
  </div>
</template>

<script setup>
import ContractCreationHeadquarterMasterInformation from './contract-creation-headquarter-master-information.vue';
import ContractCreationHeadquarterPayrollInformation from './contract-creation-headquarter-payroll-information.vue';
import ContractCreationHeadQuarterContractInformation from './contract-creation-headquarter-contract-information.vue';
import ContractCreationTurnIntoEmployeeDialog from '@/components/people/contracts/contractCreation/contractCreationDialogs/contract-creation-turn-into-employee-dialog.vue';

import languages from '@/components/people/locales/languages';
import { useContractTools } from '@/helpers/composables/contract-tools.js';
import { useRouter } from 'vue-router/composables';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';
import { onMounted, computed } from 'vue';

const peopleContractCreationStore = usePeopleContractCreationStore();
const router = useRouter();
const contractTools = useContractTools();
const { trackPage } = amplitudeComposable();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const currencyKeys = ['monthlyBaseSalary', 'annualSalary', 'bonusTarget'];

const percentageKeys = [
  'costCenter1Percentage',
  'costCenter2Percentage',
  'costCenter3Percentage',
];

// --- Methods ---
const toggleTurnIntoEmployeeDialog = () => {
  peopleContractCreationStore.displayTurnIntoEmployeeDialog =
    !peopleContractCreationStore.displayTurnIntoEmployeeDialog;
};

const redirectUser = (employeeId) => {
  router.push({
    name: 'EmployeeProfileInternalInformation',
    params: { employeeId: employeeId },
  });
};

const createEmployee = async () => {
  toggleTurnIntoEmployeeDialog();
  let payload = prepareDataToPost();
  try {
    const response = await peopleContractCreationStore.postContractCreationData(
      payload
    );
    peopleContractCreationStore.ignoreFromCreateEmployee = true;
    redirectUser(response.employeeId);
  } catch (error) {
    console.log(error);
  }
};

const prepareDataToPost = () => {
  let payload = { ...{}, ...peopleContractCreationStore.headquarterContract };

  payload.weeklyWorkingHours = contractTools.formatWeeklyWorkingHoursToPost(
    peopleContractCreationStore.headquarterContract.weeklyWorkingHours
  );

  contractTools.convertCurrencyFieldsToPost(currencyKeys, payload);

  contractTools.formatPercentageFieldsToPost(percentageKeys, payload);

  payload.totalWeeklyWorkingDays = totalActiveSwitches();

  return payload;
};

const totalActiveSwitches = () => {
  return peopleContractCreationStore.headquarterContract.weeklyWorkingHours.filter(
    (day) => day.isActive
  ).length;
};

// --- Computed ---
const disableCreateEmployeeBtn = computed(() => {
  return (
    !isFormValid.value ||
    !peopleContractCreationStore.headquarterContract.uploadedContract
  );
});

const isFormValid = computed(() => {
  return Object.values(peopleContractCreationStore.formValidations).every(
    (value) => value === true
  );
});

// --- Lifecycle hooks ---
onMounted(() => {
  trackPage();
});
</script>

<style scoped lang="scss"></style>
