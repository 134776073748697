var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"shadow-regular rounded-lg",attrs:{"data-testid":"contract-creation-search-container"}},[_c('v-card-text',{staticClass:"pb-0"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('h4',{staticClass:"heading-h4 mineBlack--text",attrs:{"data-testid":"contract-creation-search-title"}},[_vm._v(" "+_vm._s(_setup.t('Msg_.connectExistingNoventiAccount.title'))+" ")])])],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('span',{staticClass:"caption-2 mineBlack--text",attrs:{"data-testid":"contract-creation-search-details"}},[_vm._v(" "+_vm._s(_setup.t('Msg_.connectExistingNoventiAccount.details'))+" ")])])],1)],1),_c('v-form',{ref:"form",attrs:{"data-testid":"contract-creation-search-form"},model:{value:(_setup.isFormValid),callback:function ($$v) {_setup.isFormValid=$$v},expression:"isFormValid"}},[_c('v-container',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"contract-creation-search-first-name-field","label":_setup.t('Msg_.firstName') + '*',"rules":[
                _setup.rules.fieldRequired(
                  `${_setup.t('Msg_.firstName')} ${_setup.t('Msg_.isRequired')}`
                ),
                _setup.rules.valueSize(
                  100,
                  `${_setup.t('Msg_.firstName')} ${_setup.t(
                    'Msg_.rules.fieldMustHaveLessThan'
                  )} 100 ${_setup.t('Msg_.rules.characters')}`
                ),
              ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.firstName),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "firstName", $$v)},expression:"peopleContractCreationStore.careOpsContract.firstName"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{attrs:{"data-testid":"contract-creation-search-first-name-field","label":_setup.t('Msg_.lastName') + '*',"rules":[
                _setup.rules.fieldRequired(
                  `${_setup.t('Msg_.lastName')} ${_setup.t('Msg_.isRequired')}`
                ),
                _setup.rules.valueSize(
                  100,
                  `${_setup.t('Msg_.lastName')} ${_setup.t(
                    'Msg_.rules.fieldMustHaveLessThan'
                  )} 100 ${_setup.t('Msg_.rules.characters')}`
                ),
              ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.lastName),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "lastName", $$v)},expression:"peopleContractCreationStore.careOpsContract.lastName"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',[_c('v-row',{staticClass:"d-flex justify-end"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"main-action-btn mr-2 mb-2",attrs:{"data-testid":"contract-creation-search-start-search-btn","disabled":!_setup.isFormValid},on:{"click":function($event){return _setup.getRecruitments()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.startSearch'))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }