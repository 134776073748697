var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{staticClass:"shadow-regular",attrs:{"data-testid":"contract-creation-headquarter-master-info-container","value":_setup.open,"flat":""}},[_c('v-expansion-panel',{staticClass:"rounded-lg pa-2"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"data-testid":"contract-creation-headquarter-master-info-chevron-icon","color":"#212121"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}])},[_c('div',[_c('span',{staticClass:"heading-h3 mr-2",attrs:{"data-testid":"contract-creation-headquarter-master-info-title"}},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.masterInformation'))+" ")])])]),_c('v-expansion-panel-content',[_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"data-testid":"contract-creation-headquarter-master-info-form"},model:{value:(
          _setup.peopleContractCreationStore.formValidations.masterInfoValidation
        ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.formValidations, "masterInfoValidation", $$v)},expression:"\n          peopleContractCreationStore.formValidations.masterInfoValidation\n        "}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-first-name-field","label":_setup.t('Msg_.firstName'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.firstName')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.firstName')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.firstName
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "firstName", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.firstName\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-last-name-field","label":_setup.t('Msg_.lastName'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.lastName')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.lastName')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.lastName
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "lastName", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.lastName\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-gender-dropdown","label":_setup.t('Msg_.gender'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectGenderType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.gender')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.gender
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "gender", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.gender\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-birth-name-field","label":_setup.t('Msg_.birthName'),"optional":"","rules":_setup.birthNameRules},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.birthName
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "birthName", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.birthName\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-date-of-birth-picker","label":_setup.t('Msg_.dateOfBirth'),"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.dateOfBirth')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.dateOfBirth
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "dateOfBirth", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.dateOfBirth\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-nationality-field","label":_setup.t('Msg_.nationality'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.nationality')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.nationality')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.nationality
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "nationality", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.nationality\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-street-field","label":_setup.t('Msg_.street'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.street')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.street')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.street
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "street", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.street\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-houser-number-field","label":_setup.t('Msg_.houseNumber'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.houseNumber')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    10,
                    `${_setup.t('Msg_.houseNumber')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 10 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.houseNumber
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "houseNumber", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.houseNumber\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-zip-code-field","label":_setup.t('Msg_.zipCode'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.zipCode')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    10,
                    `${_setup.t('Msg_.zipCode')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 10 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.zipCode
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "zipCode", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.zipCode\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-city-field","label":_setup.t('Msg_.city'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.city')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.city')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.headquarterContract.city),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "city", $$v)},expression:"peopleContractCreationStore.headquarterContract.city"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-country-field","label":_setup.t('Msg_.country'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.country')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.country')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract.country
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract, "country", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract.country\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-personal-phone-number-field","label":_setup.t('Msg_.personalPhoneNumber'),"error":_setup.phoneNumberValidation.isInUse,"error-messages":_setup.phoneNumberValidation.isInUse
                    ? _setup.t('Msg_.rules.PhoneNumberInSquirtle')
                    : '',"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.personalPhoneNumber')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`,
                    _setup.rules.valueSize(
                      30,
                      `${_setup.t('Msg_.personalPhoneNumber')} ${_setup.t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 30 ${_setup.t('Msg_.rules.characters')}`
                    )
                  ),
                ]},on:{"change":function($event){return _setup.validatePhoneNumber(
                    _setup.peopleContractCreationStore.headquarterContract
                      .personalPhoneNumber
                  )}},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract
                    .personalPhoneNumber
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract
                    , "personalPhoneNumber", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract\n                    .personalPhoneNumber\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-personal-email-field","label":_setup.t('Msg_.personalEmail'),"error":_setup.emailValidation.isInUse,"error-messages":_setup.emailValidation.isInUse
                    ? _setup.t('Msg_.rules.emailInSquirtle')
                    : '',"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.personalEmail')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.email(_setup.t('Msg_.rules.invalidEmail')),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.personalEmail')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},on:{"change":function($event){return _setup.validateEmail(
                    _setup.peopleContractCreationStore.headquarterContract
                      .personalEmail
                  )}},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract
                    .personalEmail
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract
                    , "personalEmail", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract\n                    .personalEmail\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-emergency-contact-field","label":_setup.t('Msg_.emergencyContact'),"optional":"","rules":_setup.emergencyContactRules},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract
                    .emergencyContact
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract
                    , "emergencyContact", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract\n                    .emergencyContact\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-headquarter-master-info-emergency-contact-phone-field","label":_setup.t('Msg_.emergencyContactPhone'),"optional":"","rules":_setup.emergencyContactPhoneRules},model:{value:(
                  _setup.peopleContractCreationStore.headquarterContract
                    .emergencyContactPhone
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.headquarterContract
                    , "emergencyContactPhone", $$v)},expression:"\n                  peopleContractCreationStore.headquarterContract\n                    .emergencyContactPhone\n                "}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }