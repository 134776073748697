<template>
  <v-expansion-panels
    data-testid="contract-creation-headquarter-contract-info-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header>
        <div>
          <span
            data-testid="contract-creation-headquarter-contract-info-title"
            class="heading-h3 mr-2"
          >
            {{ t('Title_.subTitle.contractInformation') }}
          </span>
        </div>
        <template #actions>
          <v-icon
            data-testid="contract-creation-headquarter-contract-info-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          data-testid="contract-creation-headquarter-contract-info-form"
          ref="form"
          v-model="
            peopleContractCreationStore.formValidations.contractInfoValidation
          "
          class="mt-4"
        >
          <v-container class="pa-0">
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DatePicker
                  data-testid="contract-creation-headquarter-contract-info-start-date-picker"
                  class="pr-3"
                  :label="t('Msg_.startDateAtKenbi')"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.startDate
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.startDateAtKenbi')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-contract-info-job-title-dropdown"
                  class="pr-3"
                  :label="t('Msg_.jobTitle')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.jobPosition
                  "
                  :items="selectJobTitle"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.jobTitle')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                  disabled
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-job-subtitle-field"
                  class="pl-3"
                  :label="t('Msg_.jobSubtitle')"
                  optional
                  v-model="
                    peopleContractCreationStore.headquarterContract.jobSubtitle
                  "
                  :rules="[
                    rules.valueSize(
                      50,
                      `${t('Msg_.jobSubtitle')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-contract-info-main-hub-dropdown"
                  class="pr-3"
                  :label="t('Msg_.mainHub')"
                  :placeholder="t('Msg_.selectOption')"
                  :items="profileStore.profileHubs"
                  item-value="id"
                  item-text="name"
                  :clearable="false"
                  :value="
                    peopleContractCreationStore.headquarterContract.mainHub
                  "
                  disabled
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-contract-info-main-hub-dropdown"
                  class="pl-3"
                  :label="t('Msg_.company')"
                  :placeholder="t('Msg_.selectOption')"
                  :items="companies"
                  item-value="id"
                  item-text="name"
                  :clearable="false"
                  v-model="
                    peopleContractCreationStore.headquarterContract.company
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.company')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-workplace-field"
                  class="pr-3"
                  :label="t('Msg_.workplace')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.workplace
                  "
                  :rules="[
                    rules.valueSize(
                      50,
                      `${t('Msg_.workplace')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-contract-info-contract-type-dropdown"
                  class="pr-3"
                  :label="t('Msg_.contractType')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.contractType
                  "
                  :items="selectContractType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.contractType')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-compensation-type-field"
                  class="pl-3"
                  disabled
                  :label="t('Msg_.compensationType')"
                  :value="getCompensationTypeText"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-weekly-working-hours-field"
                  class="pr-3"
                  :label="t('Msg_.weeklyWorkingHours')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .totalWeeklyWorkingHours
                  "
                  @change="handleTotalWeeklyWorkingHours()"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.weeklyWorkingHours')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                    rules.numberWithDecimals(t('Msg_.rules.onlyNumbers')),
                    (v) =>
                      isWeeklyWorkingHoursValid ||
                      t('Msg_.rules.maximumValueExceeded'),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-vacation-days-field"
                  class="pl-3"
                  disabled
                  :label="t('Msg_.vacationDays')"
                  :value="vacationDaysPerYear"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.vacationDays')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <span class="custom-label pl-3 mb-1">{{
                t('Msg_.workingDays')
              }}</span>
              <v-col cols="12" class="d-flex px-0 pt-0">
                <div
                  v-for="(item, index) in peopleContractCreationStore
                    .headquarterContract.weeklyWorkingHours"
                  :key="index"
                  class="d-flex flex-column flex-grow-1"
                >
                  <SwitchControl
                    :data-testid="`contract-creation-headquarter-contract-info-week-day-${index}-switch`"
                    class="my-2"
                    :value="item.isActive"
                    @click="updateDailyWorkingHours(index)"
                    hide-details
                  />
                  <span class="pl-3 custom-label">{{
                    tm(`Action_.selectWeekDays`)[index]
                  }}</span>
                  <span
                    class="pl-3 custom-label"
                    :class="
                      item.isActive ? 'mineBlack--text' : 'greyRegular--text'
                    "
                  >
                    {{ item.hours }} {{ t('Msg_.hours') }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="my-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-contract-info-contract-period-dropdown"
                  class="pr-3"
                  :label="t('Msg_.contractPeriod')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .contractPeriod
                  "
                  :items="selectContractPeriodType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.contractPeriod')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                  data-testid="contract-creation-headquarter-contract-info-exit-date-picker"
                  class="pl-3"
                  :label="t('Msg_.exitDate')"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.exitDate
                  "
                  :rules="exitDateRules"
                  :disabled="isExitDateDisabled"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-contract-info-probation-dropdown"
                  class="pr-3"
                  :label="t('Msg_.probation')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.probation
                  "
                  :items="selectProbationType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.probation')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-contract-info-notice-period-during-probation-dropdown"
                  class="pr-3"
                  :label="t('Msg_.noticePeriodDuringProbation')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .noticePeriodDuringProbation
                  "
                  :items="selectProbationNoticePeriodType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.noticePeriodDuringProbation')} ${t(
                        'Msg_.rules.isRequired'
                      )}`,
                      0
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-contract-info-notice-period-after-probation-dropdown"
                  class="pl-3"
                  :label="t('Msg_.noticePeriodAfterProbation')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .noticePeriodAfterProbation
                  "
                  :items="selectProbationNoticePeriodAfterType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.noticePeriodAfterProbation')} ${t(
                        'Msg_.rules.isRequired'
                      )}`,
                      0
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-monthly-base-salary-field"
                  icon="mdi-currency-eur"
                  class="pr-3"
                  :label="t('Msg_.monthlyBaseSalary')"
                  :value="
                    peopleContractCreationStore.headquarterContract
                      .monthlyBaseSalary
                  "
                  @change="
                    updateCurrencyField(
                      'monthlyBaseSalary',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    updateCurrencyField(
                      'monthlyBaseSalary',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.monthlyBaseSalary')} ${t('Msg_.isRequired')}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-annual-salary-field"
                  icon="mdi-currency-eur"
                  class="pl-3"
                  :label="t('Msg_.annualSalary')"
                  :value="
                    peopleContractCreationStore.headquarterContract.annualSalary
                  "
                  @change="
                    updateCurrencyField(
                      'annualSalary',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    updateCurrencyField(
                      'annualSalary',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.annualSalary')} ${t('Msg_.isRequired')}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-bonus-target-field"
                  icon="mdi-currency-eur"
                  class="pr-3"
                  :label="t('Msg_.bonusTarget')"
                  :value="
                    peopleContractCreationStore.headquarterContract.bonusTarget
                  "
                  @change="
                    updateCurrencyField(
                      'bonusTarget',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    updateCurrencyField(
                      'bonusTarget',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-contract-info-assigner-field"
                  class="pr-3"
                  disabled
                  :label="t('Msg_.assigner')"
                  :value="userStore.userInfo.name"
                />
              </v-col>
            </v-row>
            <v-row dense class="my-5">
              <v-col cols="12" class="d-flex flex-column">
                <span class="custom-label mb-4">
                  {{ t('Msg_.contract') }}
                </span>
                <div v-if="controlDisplayInfo === 'buttons'">
                  <v-btn
                    data-testid="contract-creation-headquarter-contract-info-upload-contract-btn"
                    class="main-action-variant-transparent-btn mr-4"
                    @click="triggerFileSelector()"
                  >
                    {{ t('Action_.buttons.uploadContract') }}
                  </v-btn>
                  <input
                    v-show="false"
                    ref="fileSelector"
                    type="file"
                    accept=".pdf"
                    @change="postDocument('uploadedContract', $event)"
                  />
                </div>
                <div v-else-if="controlDisplayInfo === 'uploadedContract'">
                  <FileInput
                    deletable
                    data-testid="contract-creation-headquarter-uploaded-contract-file-input"
                    :label="t('Msg_.uploadedContract')"
                    :value="
                      peopleContractCreationStore.headquarterContract
                        .uploadedContract
                    "
                    @display-document="displayDocument('uploadedContract')"
                    @delete-document="deleteDocument('uploadedContract')"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import SwitchControl from '@/components/shared/customComponents/controls/switch-control.vue';
import FileInput from '@/components/shared/customComponents/inputs/file-input.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '@/components/people/locales/languages';
import { useUserStore } from '@/store/pinia/user.store.js';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, nextTick, onBeforeUnmount } from 'vue';

const form = ref();
const userStore = useUserStore();
const profileStore = useProfileStore();
const peopleStore = usePeopleStore();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const companies = ref([]);
const fileSelector = ref();

// --- Methods ---
const displayDocument = (field) => {
  window.open(
    `${process.env.VUE_APP_LOGIN_API}/v2/api/employee/downloadFile?url=${peopleContractCreationStore.headquarterContract[field].fileUrl}`,
    '_blank'
  );
};

const deleteDocument = async (field) => {
  const payload = {
    field,
    contract: peopleContractCreationStore.headquarterContract,
  };

  try {
    await peopleContractCreationStore.deleteDocument(payload);
  } catch (error) {
    console.log(error);
  }
};

const postDocument = (field, value) => {
  let payload = {
    field,
    value: value.target.files[0],
    contract: peopleContractCreationStore.headquarterContract,
  };
  try {
    peopleContractCreationStore.postDocument(payload);
  } catch (error) {
    console.log(error);
  }
};

const triggerFileSelector = () => {
  fileSelector.value.click();
};

const updateDailyWorkingHours = (index) => {
  updateWeekDay(index);
  peopleContractCreationStore.headquarterContract.vacationDays =
    vacationDaysPerYear.value;
  updateSplitHours();
};

const updateWeekDay = (index) => {
  let freeDay = 0;
  peopleContractCreationStore.headquarterContract.weeklyWorkingHours[
    index
  ].isActive =
    !peopleContractCreationStore.headquarterContract.weeklyWorkingHours[index]
      .isActive;

  if (
    !peopleContractCreationStore.headquarterContract.weeklyWorkingHours[index]
      .isActive
  ) {
    peopleContractCreationStore.headquarterContract.weeklyWorkingHours[
      index
    ].hours = freeDay;
  }
};

const updateCurrencyField = (field, value) => {
  peopleContractCreationStore.headquarterContract[field] = value;
};

const handleTotalWeeklyWorkingHours = () => {
  updateSplitHours();
  handleCompensationType();
};

const handleCompensationType = () => {
  let compensationType;
  if (peopleContractCreationStore.headquarterContract.contractType === 1) {
    if (
      peopleContractCreationStore.headquarterContract.totalWeeklyWorkingHours >=
      38.5
    ) {
      compensationType = 1;
    } else {
      compensationType = 2;
    }
  } else {
    compensationType = 4;
  }
  peopleContractCreationStore.headquarterContract.compensationType =
    compensationType;
};

const updateSplitHours = async () => {
  let splitTime =
    peopleContractCreationStore.headquarterContract.totalWeeklyWorkingHours /
    totalActiveSwitches();

  peopleContractCreationStore.headquarterContract.weeklyWorkingHours.forEach(
    (day, index) => {
      if (day.isActive) {
        peopleContractCreationStore.headquarterContract.weeklyWorkingHours[
          index
        ].hours = Number.isInteger(splitTime)
          ? splitTime
          : splitTime.toFixed(2);
      }
    }
  );
  await nextTick();
  form.value.validate();
};

const getCompanies = async () => {
  try {
    const response = await peopleContractCreationStore.getCompanies();
    if (response) {
      companies.value = response.items;
    }
  } catch (error) {
    console.log(error);
  }
};

const getMainHubIdByName = () => {
  const headquarterHub = profileStore.profileHubs.find(
    (hub) => hub.name === 'Headquarter'
  );
  peopleContractCreationStore.headquarterContract.mainHub = headquarterHub.id;
};

const initializeContractData = () => {
  getCompanies();
  getMainHubIdByName();
};

// --- Computed ---
const controlDisplayInfo = computed(() => {
  let display = 'buttons';
  if (peopleContractCreationStore.headquarterContract.uploadedContract) {
    display = 'uploadedContract';
  }
  return display;
});

const selectJobTitle = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectQualificationType'),
    peopleStore.peopleResources?.qualificationTypes
  );
});

const selectContractType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectContractType'),
    peopleStore.peopleResources?.contractTypes
  );
});

const selectCompensationTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectCompensationType'),
    peopleStore.peopleResources?.contractCreationCompensationTypes
  );
});

const getCompensationTypeText = computed(() => {
  const compensationTypes = selectCompensationTypes.value.find(
    (type) =>
      type.value ===
      peopleContractCreationStore.headquarterContract.compensationType
  );
  return compensationTypes?.text;
});

const vacationDaysPerYear = computed(() => {
  const totalWorkingDays = totalActiveSwitches();

  if (
    peopleStore.peopleResources?.vacationDaysCalculations &&
    totalWorkingDays !== 7
  ) {
    const selectedVacationDaysCalculations =
      peopleStore.peopleResources?.vacationDaysCalculations.find(
        (days) => days.weeklyWorkingDays === totalWorkingDays
      );
    return selectedVacationDaysCalculations.yearlyVacationDays;
  }

  return null;
});

const isExitDateDisabled = computed(() => {
  return peopleContractCreationStore.headquarterContract.contractPeriod === 1;
});

const exitDateRules = computed(() =>
  isExitDateDisabled.value
    ? []
    : [
        rules.fieldRequired(
          `${t('Msg_.exitDate')} ${t('Msg_.rules.isRequired')}`
        ),
      ]
);

const totalActiveSwitches = () => {
  return peopleContractCreationStore.headquarterContract.weeklyWorkingHours.filter(
    (day) => day.isActive
  ).length;
};

const isWeeklyWorkingHoursValid = computed(() => {
  let maxHoursValid = null;
  const activeDays = totalActiveSwitches();

  if (
    peopleContractCreationStore.headquarterContract.totalWeeklyWorkingHours !==
      null &&
    peopleContractCreationStore.headquarterContract.totalWeeklyWorkingHours !==
      '' &&
    activeDays > 0 &&
    peopleContractCreationStore.headquarterContract.totalWeeklyWorkingHours /
      activeDays <=
      24
  ) {
    maxHoursValid = true;
  }

  return maxHoursValid;
});

const selectContractPeriodType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectContractPeriodType'),
    peopleStore.peopleResources?.contractPeriodTypes
  );
});

const selectProbationType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationType'),
    peopleStore.peopleResources?.probationTypes
  );
});

const selectProbationNoticePeriodType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationNoticePeriodType'),
    peopleStore.peopleResources?.probationNoticePeriodTypes
  );
});

const selectProbationNoticePeriodAfterType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationNoticePeriodAfterTypes'),
    peopleStore.peopleResources?.probationNoticePeriodAfterTypes
  );
});

// Lifecycle hooks
initializeContractData();

onBeforeUnmount(() => {
  peopleContractCreationStore.headquarterContract.weeklyWorkingHours =
    peopleContractCreationStore.getEmptyWeeklyWorkingHours();
});
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;

  .hours-label {
    color: $grey-regular;
  }
}
.element-border {
  border-top: 1px solid $ash-regular;
  border-radius: 4px;
}
</style>
