var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{staticClass:"shadow-regular",attrs:{"data-testid":"contract-creation-care-ops-contract-info-container","value":_setup.open,"flat":""}},[_c('v-expansion-panel',{staticClass:"rounded-lg pa-2"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"data-testid":"contract-creation-care-ops-contract-info-chevron-icon","color":"#212121"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}])},[_c('div',[_c('span',{staticClass:"heading-h3 mr-2",attrs:{"data-testid":"contract-creation-care-ops-contract-info-title"}},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.contractInformation'))+" ")])])]),_c('v-expansion-panel-content',[_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"data-testid":"contract-creation-care-ops-contract-info-form"},model:{value:(
          _setup.peopleContractCreationStore.formValidations.contractInfoValidation
        ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.formValidations, "contractInfoValidation", $$v)},expression:"\n          peopleContractCreationStore.formValidations.contractInfoValidation\n        "}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-start-date-picker","label":_setup.t('Msg_.startDateAtKenbi'),"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.startDateAtKenbi')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.startDate
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "startDate", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.startDate\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-origin-dropdown","label":_setup.t('Msg_.origin'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectOriginType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.origin')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.origin),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "origin", $$v)},expression:"peopleContractCreationStore.careOpsContract.origin"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-start-date-prior-picker","label":_setup.t('Msg_.startDatePriorToAcquisition'),"placeholder":_setup.t('Msg_.datePlaceholder'),"disabled":_setup.isStartDateDisabled,"rules":_setup.startDateRules},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .startDatePriorToAcquisition
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "startDatePriorToAcquisition", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .startDatePriorToAcquisition\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-job-title-dropdown","label":_setup.t('Msg_.jobTitle'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectQualificationType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.jobTitle')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.jobPosition
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "jobPosition", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.jobPosition\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-job-subtitle-field","label":_setup.t('Msg_.jobSubtitle'),"optional":"","rules":[
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.jobSubtitle')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.jobSubtitle
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "jobSubtitle", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.jobSubtitle\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.Combobox,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-additional-training-combobox","label":_setup.t('Msg_.additionalTraining'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectAdditionalTrainingType,"value":_setup.peopleContractCreationStore.careOpsContract
                    .additionalTraining,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.additionalTraining')} ${_setup.t('Msg_.isRequired')}`
                  ),
                  (v) =>
                    v?.length > 0 ||
                    `${_setup.t('Msg_.additionalTraining')} ${_setup.t('Msg_.isRequired')}`,
                ]},on:{"click":function($event){return _setup.handleAdditionaTrainingSelection($event)}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-highest-school-degree-dropdown","label":_setup.t('Msg_.highestSchoolDegree'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectLevelOfEducationType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.highestSchoolDegree')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .highestSchoolDegree
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "highestSchoolDegree", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .highestSchoolDegree\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-experience-at-start-field","label":_setup.t('Msg_.experienceAtStartInYears'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.experienceAtStartInYears')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                  _setup.rules.onlyNumbers(_setup.t('Msg_.rules.onlyNumbers')),
                  _setup.rules.validateNumberInRange(
                    0,
                    99,
                    _setup.t('Msg_.rules.maximumValueExceeded')
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .yearsOfExperience
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "yearsOfExperience", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .yearsOfExperience\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-main-hub-dropdown","label":_setup.t('Msg_.mainHub'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.profileStore.profileHubs,"item-value":"id","item-text":"name","clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.mainHub')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.mainHub),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "mainHub", $$v)},expression:"peopleContractCreationStore.careOpsContract.mainHub"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-billing-hub-field","disabled":"","label":_setup.t('Msg_.billingHub'),"value":_setup.billingHub}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-company-field","disabled":"","label":_setup.t('Msg_.company'),"value":_setup.companyName}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-contract-type-dropdown","label":_setup.t('Msg_.contractType'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectContractType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.contractType')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},on:{"change":function($event){return _setup.handleCompensationType()}},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.contractType
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "contractType", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.contractType\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-compensation-type-field","disabled":"","label":_setup.t('Msg_.compensationType'),"value":_setup.getCompensationTypeText}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-weekly-working-hours-field","label":_setup.t('Msg_.weeklyWorkingHours'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.weeklyWorkingHours')} ${_setup.t('Msg_.isRequired')}`
                  ),
                  _setup.rules.numberWithDecimals(_setup.t('Msg_.rules.onlyNumbers')),
                  (v) =>
                    _setup.isWeeklyWorkingHoursValid ||
                    _setup.t('Msg_.rules.maximumValueExceeded'),
                ]},on:{"change":function($event){return _setup.handleTotalWeeklyWorkingHours()}},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .totalWeeklyWorkingHours
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "totalWeeklyWorkingHours", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .totalWeeklyWorkingHours\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-vacation-days-field","disabled":"","label":_setup.t('Msg_.vacationDays'),"value":_setup.peopleContractCreationStore.careOpsContract.vacationDays}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('span',{staticClass:"custom-label pl-3 mb-1"},[_vm._v(_vm._s(_setup.t('Msg_.workingDays')))]),_c('v-col',{staticClass:"d-flex px-0 pt-0",attrs:{"cols":"12"}},_vm._l((_setup.peopleContractCreationStore
                  .careOpsContract.weeklyWorkingHours),function(item,index){return _c('div',{key:index,staticClass:"d-flex flex-column flex-grow-1"},[_c(_setup.SwitchControl,{staticClass:"my-2",attrs:{"data-testid":`contract-creation-care-ops-contract-info-week-day-${index}-switch`,"value":item.isActive,"hide-details":""},on:{"click":function($event){return _setup.handleWeeklyWorkingHours(index)}}}),_c('span',{staticClass:"pl-3 custom-label"},[_vm._v(_vm._s(_setup.tm(`Action_.selectWeekDays`)[index]))]),_c('span',{staticClass:"pl-3 custom-label",class:item.isActive ? 'mineBlack--text' : 'greyRegular--text'},[_vm._v(" "+_vm._s(item.hours)+" "+_vm._s(_setup.t('Msg_.hours'))+" ")])],1)}),0)],1),_c('v-row',{staticClass:"my-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-contract-period-dropdown","label":_setup.t('Msg_.contractPeriod'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectContractPeriodType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.contractPeriod')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.contractPeriod
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "contractPeriod", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.contractPeriod\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-exit-date-picker","label":_setup.t('Msg_.exitDate'),"disabled":_setup.isContractUnlimited,"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":_setup.exitDateRules},model:{value:(_setup.peopleContractCreationStore.careOpsContract.exitDate),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "exitDate", $$v)},expression:"peopleContractCreationStore.careOpsContract.exitDate"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-probation-dropdown","label":_setup.t('Msg_.probation'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectProbationType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.probation')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.probation
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "probation", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.probation\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-notice-period-during-probation-dropdown","label":_setup.t('Msg_.noticePeriodDuringProbation'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectProbationNoticePeriodType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.noticePeriodDuringProbation')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`,
                    0
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .noticePeriodDuringProbation
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "noticePeriodDuringProbation", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .noticePeriodDuringProbation\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-notice-period-after-probation-dropdown","label":_setup.t('Msg_.noticePeriodAfterProbation'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectProbationNoticePeriodAfterType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.noticePeriodAfterProbation')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`,
                    0
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .noticePeriodAfterProbation
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "noticePeriodAfterProbation", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .noticePeriodAfterProbation\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-job-level-dropdown","label":_setup.t('Msg_.jobLevel'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectJobLevelType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.jobLevel')} ${_setup.t('Msg_.rules.isRequired')}`,
                    0
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.jobLevel),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "jobLevel", $$v)},expression:"peopleContractCreationStore.careOpsContract.jobLevel"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-experience-level-dropdown","label":_setup.t('Msg_.experienceLevel'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectExperienceLevelType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.experienceLevel')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`,
                    0
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.experienceLevel
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "experienceLevel", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.experienceLevel\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-hourly-wage-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.hourlyWage'),"value":_setup.peopleContractCreationStore.careOpsContract.hourlyWage,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.hourlyWage')} ${_setup.t('Msg_.isRequired')}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.handleCurrencyField(
                    'hourlyWage',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.handleCurrencyField(
                    'hourlyWage',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-city-bonus-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.cityBonus'),"value":_setup.peopleContractCreationStore.careOpsContract.cityBonus,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.cityBonus')} ${_setup.t('Msg_.isRequired')}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.handleCurrencyField(
                    'cityBonus',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.handleCurrencyField(
                    'cityBonus',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-care-allowance-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.careAllowance'),"value":_setup.peopleContractCreationStore.careOpsContract.careAllowance,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.careAllowance')} ${_setup.t('Msg_.isRequired')}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.handleCurrencyField(
                    'careAllowance',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.handleCurrencyField(
                    'careAllowance',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-non-dynamic-allowance-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.nonDynamicAllowance'),"value":_setup.peopleContractCreationStore.careOpsContract
                    .nonDynamicAllowance,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.nonDynamicAllowance')} ${_setup.t(
                      'Msg_.isRequired'
                    )}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.handleCurrencyField(
                    'nonDynamicAllowance',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.handleCurrencyField(
                    'nonDynamicAllowance',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-individual-allowance-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.individualAllowance'),"value":_setup.peopleContractCreationStore.careOpsContract
                    .individualAllowance,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.individualAllowance')} ${_setup.t(
                      'Msg_.isRequired'
                    )}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.handleCurrencyField(
                    'individualAllowance',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.handleCurrencyField(
                    'individualAllowance',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-monthly-base-salary-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.monthlyBaseSalary'),"value":_setup.peopleContractCreationStore.careOpsContract
                    .monthlyBaseSalary,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.monthlyBaseSalary')} ${_setup.t('Msg_.isRequired')}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.handleCurrencyField(
                    'monthlyBaseSalary',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.handleCurrencyField(
                    'monthlyBaseSalary',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-thirtheenth-salary-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.13thSalary'),"value":_setup.peopleContractCreationStore.careOpsContract.thirteenthSalary,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.13thSalary')} ${_setup.t('Msg_.isRequired')}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.handleCurrencyField(
                    'thirteenthSalary',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.handleCurrencyField(
                    'thirteenthSalary',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-annual-salary-field","icon":"mdi-currency-eur","label":_setup.t('Msg_.annualSalary'),"value":_setup.peopleContractCreationStore.careOpsContract.annualSalary,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.annualSalary')} ${_setup.t('Msg_.isRequired')}`,
                    0
                  ),
                  _setup.rules.currency(_setup.t('Msg_.rules.onlyNumbersAndDecimals')),
                  _setup.rules.currencySmallerOrEqualThan(
                    1000000,
                    _setup.t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                  ),
                ]},on:{"change":function($event){_setup.handleCurrencyField(
                    'annualSalary',
                    _setup.helpers.convertValueToCurrency($event)
                  )},"focus":function($event){_setup.handleCurrencyField(
                    'annualSalary',
                    _setup.helpers.convertCurrencyToFloat($event)
                  )}}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-signing-date-picker","label":_setup.t('Msg_.signingDate'),"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.signingDate')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.signingDate
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "signingDate", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.signingDate\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-contract-info-assigner-field","disabled":"","label":_setup.t('Msg_.assigner'),"value":_setup.userStore.userInfo.name}})],1)],1),_c('v-row',{staticClass:"my-5",attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12"}},[_c('span',{staticClass:"custom-label mb-4"},[_vm._v(" "+_vm._s(_setup.t('Msg_.contract'))+" ")]),(_setup.controlDisplayInfo === 'buttons')?_c('div',[_c('v-btn',{staticClass:"main-action-variant-btn mr-4",attrs:{"data-testid":"contract-creation-care-ops-contract-info-generate-contract-btn","disabled":_setup.isContractButtonDisabled,"loading":_setup.isGeneratingNewContract},on:{"click":function($event){return _setup.generateNewContract()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.generateContract'))+" ")]),(!_setup.isEditing)?_c('v-btn',{staticClass:"main-action-variant-btn",attrs:{"data-testid":"contract-creation-care-ops-contract-info-upload-contract-btn","disabled":_setup.isContractButtonDisabled},on:{"click":function($event){return _setup.triggerFileSelector()}}},[_vm._v(" "+_vm._s(_setup.t('Action_.buttons.uploadContract'))+" ")]):_vm._e(),_c('input',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"fileSelector",attrs:{"type":"file","accept":".pdf"},on:{"change":function($event){return _setup.postDocument('uploadedContract', $event)}}})],1):(_setup.controlDisplayInfo === 'uploadedContract')?_c('div',[_c(_setup.FileInput,{attrs:{"deletable":"","data-testid":"contract-creation-care-ops-uploaded-contract-file-input","label":_setup.t('Msg_.uploadedContract'),"value":_setup.peopleContractCreationStore.careOpsContract
                      .uploadedContract},on:{"display-document":function($event){return _setup.displayDocument(
                      '/v2/api/employee/downloadFile?url=',
                      _setup.peopleContractCreationStore.careOpsContract
                        .uploadedContract.fileUrl
                    )},"delete-document":function($event){return _setup.deleteDocument('uploadedContract')}}})],1):(_setup.controlDisplayInfo === 'generatedContract')?_c('div',[_c(_setup.FileInput,{attrs:{"deletable":"","data-testid":"contract-creation-care-ops-generated-contract-file-input","label":_setup.t('Msg_.generatedContract'),"value":`${_setup.peopleContractCreationStore.careOpsContract.generatedContractId}.pdf`},on:{"display-document":function($event){return _setup.displayDocument(
                      '/v2/api/recruitments/documents?path=',
                      _setup.documentPath
                    )},"delete-document":function($event){return _setup.clearGeneratedContractData()}}})],1):_vm._e()])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }