<template>
  <v-container class="mt-4 card-container">
    <v-row class="py-3">
      <v-col cols="12" class="pa-0">
        <h2 data-testid="contract-creation-header" class="heading-h2 mb-7">
          {{ contractCreationTitle }}
        </h2>
        <ContractCreationTypeSelection
          v-if="peopleContractCreationStore.currentScreen === 'typeSelection'"
        />
        <ContractCreationSearch
          v-if="peopleContractCreationStore.currentScreen === 'search'"
        />
        <ContractCreationResults
          v-if="peopleContractCreationStore.currentScreen === 'results'"
        />
        <ContractCreationNoResults
          v-if="peopleContractCreationStore.currentScreen === 'noResults'"
        />
        <ContractCreationCareOpsForm
          v-if="peopleContractCreationStore.currentScreen === 'careOpsForm'"
        />
        <ContractCreationHeadquarterForm
          v-if="peopleContractCreationStore.currentScreen === 'headquarterForm'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import ContractCreationTypeSelection from './contract-creation-type-selection.vue';
import ContractCreationSearch from './careOpsFlow/contract-creation-search.vue';
import ContractCreationResults from './careOpsFlow/contract-creation-results.vue';
import ContractCreationNoResults from './careOpsFlow/contract-creation-no-results.vue';
import ContractCreationCareOpsForm from './careOpsFlow/careOpsForm/contract-creation-care-ops-form.vue';
import ContractCreationHeadquarterForm from './headquarterFlow/headquarterForm/contract-creation-headquarter-form.vue';
import languages from '@/components/people/locales/languages';
import { useRoute } from 'vue-router/composables';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useContractToolsV2 } from '@/helpers/composables/contract-tools-v2.js';
import { useI18n } from 'vue-i18n-bridge';
import { computed, onBeforeUnmount } from 'vue';

const route = useRoute();
const peopleStore = usePeopleStore();
const profileStore = useProfileStore();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t } = useI18n({ locale: 'en', messages: languages });
const {
  initiateWeeklyWorkingHours,
  totalActiveSwitches,
  initiateAdditionalTraining,
  initiateOptionalFields,
  initiateCurrencyFields,
} = useContractToolsV2();

// --- Local variables ---
const optionalFields = [
  'birthName',
  'emergencyContact',
  'emergencyContactPhone',
  'lbnr',
  'signingDate',
];
const currencyKeys = [
  'hourlyWage',
  'cityBonus',
  'careAllowance',
  'nonDynamicAllowance',
  'individualAllowance',
  'monthlyBaseSalary',
  'thirteenthSalary',
  'annualSalary',
];

// --- Methods ---
const getProfileHubList = async () => {
  try {
    await profileStore.getProfileHubList();
  } catch (error) {
    console.log(error);
  }
};

const getContractDataById = async () => {
  try {
    const response = await peopleContractCreationStore.getContractDataById(
      route.params.employeeId
    );
    peopleContractCreationStore.careOpsContract = {
      ...peopleContractCreationStore.careOpsContract,
      ...response,
    };
  } catch (error) {
    console.log(error);
  }
};

const initializeContractData = () => {
  initiateWeeklyWorkingHours(peopleContractCreationStore.careOpsContract);
  peopleContractCreationStore.careOpsContract.vacationDays =
    vacationDaysPerYear.value;
  initiateAdditionalTraining(peopleContractCreationStore.careOpsContract);
  initiateOptionalFields(
    optionalFields,
    peopleContractCreationStore.careOpsContract
  );
  initiateCurrencyFields(
    currencyKeys,
    peopleContractCreationStore.careOpsContract
  );
  if (
    !peopleContractCreationStore.careOpsContract.startDatePriorToAcquisition
  ) {
    peopleContractCreationStore.careOpsContract.startDatePriorToAcquisition =
      '';
  }
};

const checkIsEditing = async () => {
  if (isEditing.value) {
    await getContractDataById();
    initializeContractData();
    peopleContractCreationStore.currentScreen = 'careOpsForm';
  }
};

const initiateForm = async () => {
  await getProfileHubList();
  await checkIsEditing();
};

// --- Computed ---
const isEditing = computed(() => {
  return !!route.params.employeeId;
});

const contractCreationTitle = computed(() => {
  if (peopleContractCreationStore.currentScreen !== 'typeSelection') {
    if (peopleContractCreationStore.isHeadquarterEmployee) {
      return t('Title_.page.createHeadquartersEmployee');
    } else {
      return t('Title_.page.createCareOpsEmployee');
    }
  } else {
    return t('Title_.page.createEmployee');
  }
});

const vacationDaysPerYear = computed(() => {
  const totalWorkingDays = totalActiveSwitches(
    peopleContractCreationStore.careOpsContract
  );

  if (
    peopleStore.peopleResources?.vacationDaysCalculations &&
    totalWorkingDays !== 7
  ) {
    const selectedVacationDaysCalculations =
      peopleStore.peopleResources?.vacationDaysCalculations.find(
        (days) => days.weeklyWorkingDays === totalWorkingDays
      );
    return selectedVacationDaysCalculations.yearlyVacationDays;
  }

  return null;
});

// --- Lifecycle hooks ---
initiateForm();

onBeforeUnmount(() => {
  peopleContractCreationStore.$reset();
});
</script>

<style scoped lang="scss">
.card-container {
  max-width: 736px;
}
</style>
