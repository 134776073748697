<template>
  <v-expansion-panels
    data-testid="contract-creation-headquarter-payroll-info-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header>
        <div>
          <span
            data-testid="contract-creation-headquarter-payroll-info-title"
            class="heading-h3 mr-2"
          >
            {{ t('Title_.subTitle.payrollInformation') }}
          </span>
        </div>
        <template #actions>
          <v-icon
            data-testid="contract-creation-headquarter-payroll-info-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          data-testid="contract-creation-headquarter-payroll-info-form"
          ref="form"
          v-model="
            peopleContractCreationStore.formValidations.payrollInfoValidation
          "
          class="mt-4"
        >
          <v-container class="pa-0">
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-tax-id-field"
                  class="pr-3"
                  :label="t('Msg_.taxId')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.taxId
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.taxId')} ${t('Msg_.isRequired')}`
                    ),
                    rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
                    rules.totalSize(11, t('Msg_.rules.mustHaveElevenDigits')),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-payroll-tax-class-dropdown"
                  class="pl-3"
                  :label="t('Msg_.taxClass')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.taxClass
                  "
                  :items="selectTaxClassType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.taxClass')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-number-of-children-field"
                  class="pr-3"
                  :label="t('Msg_.numberOfChildren')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .numberOfChildren
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.numberOfChildren')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                    rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
                    rules.validateNumberInRange(
                      0,
                      99,
                      t('Msg_.rules.maximumValueExceeded')
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-payroll-degree-of-disability-dropdown"
                  class="pl-3"
                  :label="t('Msg_.degreeOfDisability')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .degreeOfDisability
                  "
                  :items="selectDisabilityType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.degreeOfDisability')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-payroll-other-job-dropdown"
                  class="pr-3"
                  :label="t('Msg_.otherJobCompensationType')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .otherJobCompensationType
                  "
                  :items="selectOtherJobCompensationType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.otherJobCompensationType')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-payroll-confession-dropdown"
                  class="pl-3"
                  :label="t('Msg_.confession')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.confession
                  "
                  :items="selectReligionTypes"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.confession')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-payroll-health-insurance-type-dropdown"
                  class="pr-3"
                  :label="t('Msg_.healthInsuranceType')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .healthInsuranceType
                  "
                  :items="selectHealthInsuranceType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.healthInsuranceType')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-health-insurance-name-field"
                  class="pl-3"
                  :label="t('Msg_.healthInsuranceName')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .healthInsuranceName
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.healthInsuranceName')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                    rules.valueSize(
                      50,
                      `${t('Msg_.healthInsuranceName')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-social-security-number-field"
                  class="pr-3"
                  :label="t('Msg_.socialSecurityNumber')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .socialSecurityNumber
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.socialSecurityNumber')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                    rules.valueSize(
                      30,
                      `${t('Msg_.socialSecurityNumber')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 30 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-account-owner-field"
                  class="pr-3"
                  :label="t('Msg_.accountOwner')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.accountOwner
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.accountOwner')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      50,
                      `${t('Msg_.accountOwner')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-headquarter-payroll-payment-method-dropdown"
                  class="pl-3"
                  :label="t('Msg_.paymentMethod')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .paymentMethod
                  "
                  :items="selectPaymentType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.paymentMethod')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-iban-field"
                  class="pr-3"
                  :label="t('Msg_.iban')"
                  v-model="
                    peopleContractCreationStore.headquarterContract.ibanNumber
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.iban')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.iban(t('Msg_.rules.invalidIban')),
                    rules.valueSize(
                      50,
                      `${t('Msg_.iban')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>

            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>

            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-cost-center-1-field"
                  class="pr-3"
                  :label="t('Msg_.costCenter1')"
                  optional
                  v-model="
                    peopleContractCreationStore.headquarterContract.costCenter1
                  "
                  :rules="[
                    rules.valueSize(
                      50,
                      `${t('Msg_.costCenter1')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>

              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-cost-center-1-percentage-field"
                  class="pl-3"
                  :label="t('Msg_.costCenter1Percentage')"
                  optional
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .costCenter1Percentage
                  "
                  :rules="[
                    rules.numberWithDecimalsOrNull(t('Msg_.rules.onlyNumbers')),
                    rules.smallerOrEqualThan(
                      100,
                      t('Msg_.rules.maximumValueExceeded')
                    ),
                  ]"
                />
              </v-col>
            </v-row>

            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-cost-center-2-field"
                  class="pr-3"
                  :label="t('Msg_.costCenter2')"
                  optional
                  v-model="
                    peopleContractCreationStore.headquarterContract.costCenter2
                  "
                  :rules="[
                    rules.valueSize(
                      50,
                      `${t('Msg_.costCenter2')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>

              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-cost-center-2-percentage-field"
                  class="pl-3"
                  :label="t('Msg_.costCenter2Percentage')"
                  optional
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .costCenter2Percentage
                  "
                  :rules="[
                    rules.numberWithDecimalsOrNull(t('Msg_.rules.onlyNumbers')),
                    rules.smallerOrEqualThan(
                      100,
                      t('Msg_.rules.maximumValueExceeded')
                    ),
                  ]"
                />
              </v-col>
            </v-row>

            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-cost-center-3-field"
                  class="pr-3"
                  :label="t('Msg_.costCenter3')"
                  optional
                  v-model="
                    peopleContractCreationStore.headquarterContract.costCenter3
                  "
                  :rules="[
                    rules.valueSize(
                      50,
                      `${t('Msg_.costCenter3')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>

              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-headquarter-payroll-info-cost-center-3-percentage-field"
                  class="pl-3"
                  :label="t('Msg_.costCenter3Percentage')"
                  optional
                  v-model="
                    peopleContractCreationStore.headquarterContract
                      .costCenter3Percentage
                  "
                  :rules="[
                    rules.numberWithDecimalsOrNull(t('Msg_.rules.onlyNumbers')),
                    rules.smallerOrEqualThan(
                      100,
                      t('Msg_.rules.maximumValueExceeded')
                    ),
                  ]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '@/components/people/locales/languages';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed } from 'vue';

const form = ref();
const peopleStore = usePeopleStore();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);

// --- Computed ---
const selectTaxClassType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectTaxClassTypes'),
    peopleStore.peopleResources?.taxClassTypes
  );
});

const selectDisabilityType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectDisabilityType'),
    peopleStore.peopleResources?.disabilityTypes
  );
});

const selectOtherJobCompensationType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectOtherJobCompensationType'),
    peopleStore.peopleResources?.otherJobCompensationTypes
  );
});

const selectReligionTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectReligionTypes'),
    peopleStore.peopleResources?.religionTypes
  );
});

const selectHealthInsuranceType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectHealthInsuranceType'),
    peopleStore.peopleResources?.healthInsuranceTypes
  );
});

const selectPaymentType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectPaymentType'),
    peopleStore.peopleResources?.paymentTypes
  );
});
</script>

<style scoped lang="scss">
.element-border {
  border-top: 1px solid $ash-regular;
  border-radius: 4px;
}
</style>
