<template>
  <v-expansion-panels
    data-testid="contract-creation-care-ops-contract-info-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header>
        <div>
          <span
            data-testid="contract-creation-care-ops-contract-info-title"
            class="heading-h3 mr-2"
          >
            {{ t('Title_.subTitle.contractInformation') }}
          </span>
        </div>
        <template #actions>
          <v-icon
            data-testid="contract-creation-care-ops-contract-info-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          data-testid="contract-creation-care-ops-contract-info-form"
          class="mt-4"
          ref="form"
          v-model="
            peopleContractCreationStore.formValidations.contractInfoValidation
          "
        >
          <v-container class="pa-0">
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DatePicker
                  data-testid="contract-creation-care-ops-contract-info-start-date-picker"
                  class="pr-3"
                  :label="t('Msg_.startDateAtKenbi')"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.startDate
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.startDateAtKenbi')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-origin-dropdown"
                  class="pr-3"
                  :label="t('Msg_.origin')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="peopleContractCreationStore.careOpsContract.origin"
                  :items="selectOriginType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.origin')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                  data-testid="contract-creation-care-ops-contract-info-start-date-prior-picker"
                  class="pl-3"
                  :label="t('Msg_.startDatePriorToAcquisition')"
                  :placeholder="t('Msg_.datePlaceholder')"
                  :disabled="isStartDateDisabled"
                  v-model="
                    peopleContractCreationStore.careOpsContract
                      .startDatePriorToAcquisition
                  "
                  :rules="startDateRules"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-job-title-dropdown"
                  class="pr-3"
                  :label="t('Msg_.jobTitle')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.jobPosition
                  "
                  :items="selectQualificationType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.jobTitle')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-job-subtitle-field"
                  class="pl-3"
                  :label="t('Msg_.jobSubtitle')"
                  optional
                  v-model="
                    peopleContractCreationStore.careOpsContract.jobSubtitle
                  "
                  :rules="[
                    rules.valueSize(
                      50,
                      `${t('Msg_.jobSubtitle')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <Combobox
                  data-testid="contract-creation-care-ops-contract-info-additional-training-combobox"
                  class="pr-3"
                  :label="t('Msg_.additionalTraining')"
                  :placeholder="t('Msg_.selectOption')"
                  :items="selectAdditionalTrainingType"
                  :value="
                    peopleContractCreationStore.careOpsContract
                      .additionalTraining
                  "
                  :clearable="false"
                  @click="handleAdditionaTrainingSelection($event)"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.additionalTraining')} ${t('Msg_.isRequired')}`
                    ),
                    (v) =>
                      v?.length > 0 ||
                      `${t('Msg_.additionalTraining')} ${t('Msg_.isRequired')}`,
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-highest-school-degree-dropdown"
                  class="pl-3"
                  :label="t('Msg_.highestSchoolDegree')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.careOpsContract
                      .highestSchoolDegree
                  "
                  :items="selectLevelOfEducationType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.highestSchoolDegree')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-experience-at-start-field"
                  class="pr-3"
                  :label="t('Msg_.experienceAtStartInYears')"
                  v-model="
                    peopleContractCreationStore.careOpsContract
                      .yearsOfExperience
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.experienceAtStartInYears')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                    rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
                    rules.validateNumberInRange(
                      0,
                      99,
                      t('Msg_.rules.maximumValueExceeded')
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-main-hub-dropdown"
                  class="pr-3"
                  :label="t('Msg_.mainHub')"
                  :placeholder="t('Msg_.selectOption')"
                  :items="profileStore.profileHubs"
                  item-value="id"
                  item-text="name"
                  :clearable="false"
                  v-model="peopleContractCreationStore.careOpsContract.mainHub"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.mainHub')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-billing-hub-field"
                  class="pl-3"
                  disabled
                  :label="t('Msg_.billingHub')"
                  :value="billingHub"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-company-field"
                  class="pr-3"
                  disabled
                  :label="t('Msg_.company')"
                  :value="companyName"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-contract-type-dropdown"
                  class="pr-3"
                  :label="t('Msg_.contractType')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.contractType
                  "
                  :items="selectContractType"
                  :clearable="false"
                  @change="handleCompensationType()"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.contractType')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-compensation-type-field"
                  class="pl-3"
                  disabled
                  :label="t('Msg_.compensationType')"
                  :value="getCompensationTypeText"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-weekly-working-hours-field"
                  class="pr-3"
                  :label="t('Msg_.weeklyWorkingHours')"
                  v-model="
                    peopleContractCreationStore.careOpsContract
                      .totalWeeklyWorkingHours
                  "
                  @change="handleTotalWeeklyWorkingHours()"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.weeklyWorkingHours')} ${t('Msg_.isRequired')}`
                    ),
                    rules.numberWithDecimals(t('Msg_.rules.onlyNumbers')),
                    (v) =>
                      isWeeklyWorkingHoursValid ||
                      t('Msg_.rules.maximumValueExceeded'),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-vacation-days-field"
                  class="pl-3"
                  disabled
                  :label="t('Msg_.vacationDays')"
                  :value="
                    peopleContractCreationStore.careOpsContract.vacationDays
                  "
                />
              </v-col>
            </v-row>
            <v-row dense>
              <span class="custom-label pl-3 mb-1">{{
                t('Msg_.workingDays')
              }}</span>
              <v-col cols="12" class="d-flex px-0 pt-0">
                <div
                  v-for="(item, index) in peopleContractCreationStore
                    .careOpsContract.weeklyWorkingHours"
                  :key="index"
                  class="d-flex flex-column flex-grow-1"
                >
                  <SwitchControl
                    :data-testid="`contract-creation-care-ops-contract-info-week-day-${index}-switch`"
                    class="my-2"
                    :value="item.isActive"
                    @click="handleWeeklyWorkingHours(index)"
                    hide-details
                  />
                  <span class="pl-3 custom-label">{{
                    tm(`Action_.selectWeekDays`)[index]
                  }}</span>
                  <span
                    class="pl-3 custom-label"
                    :class="
                      item.isActive ? 'mineBlack--text' : 'greyRegular--text'
                    "
                  >
                    {{ item.hours }} {{ t('Msg_.hours') }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row dense class="my-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-contract-period-dropdown"
                  class="pr-3"
                  :label="t('Msg_.contractPeriod')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.contractPeriod
                  "
                  :items="selectContractPeriodType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.contractPeriod')} ${t(
                        'Msg_.rules.isRequired'
                      )}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DatePicker
                  data-testid="contract-creation-care-ops-contract-info-exit-date-picker"
                  class="pl-3"
                  :label="t('Msg_.exitDate')"
                  :disabled="isContractUnlimited"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="peopleContractCreationStore.careOpsContract.exitDate"
                  :rules="exitDateRules"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-probation-dropdown"
                  class="pr-3"
                  :label="t('Msg_.probation')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.probation
                  "
                  :items="selectProbationType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.probation')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-notice-period-during-probation-dropdown"
                  class="pr-3"
                  :label="t('Msg_.noticePeriodDuringProbation')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.careOpsContract
                      .noticePeriodDuringProbation
                  "
                  :items="selectProbationNoticePeriodType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.noticePeriodDuringProbation')} ${t(
                        'Msg_.rules.isRequired'
                      )}`,
                      0
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-notice-period-after-probation-dropdown"
                  class="pl-3"
                  :label="t('Msg_.noticePeriodAfterProbation')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.careOpsContract
                      .noticePeriodAfterProbation
                  "
                  :items="selectProbationNoticePeriodAfterType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.noticePeriodAfterProbation')} ${t(
                        'Msg_.rules.isRequired'
                      )}`,
                      0
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-job-level-dropdown"
                  class="pr-3"
                  :label="t('Msg_.jobLevel')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="peopleContractCreationStore.careOpsContract.jobLevel"
                  :items="selectJobLevelType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.jobLevel')} ${t('Msg_.rules.isRequired')}`,
                      0
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-contract-info-experience-level-dropdown"
                  class="pl-3"
                  :label="t('Msg_.experienceLevel')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.experienceLevel
                  "
                  :items="selectExperienceLevelType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.experienceLevel')} ${t(
                        'Msg_.rules.isRequired'
                      )}`,
                      0
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-hourly-wage-field"
                  icon="mdi-currency-eur"
                  class="pr-3"
                  :label="t('Msg_.hourlyWage')"
                  :value="
                    peopleContractCreationStore.careOpsContract.hourlyWage
                  "
                  @change="
                    handleCurrencyField(
                      'hourlyWage',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    handleCurrencyField(
                      'hourlyWage',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.hourlyWage')} ${t('Msg_.isRequired')}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-city-bonus-field"
                  icon="mdi-currency-eur"
                  class="pl-3"
                  :label="t('Msg_.cityBonus')"
                  :value="peopleContractCreationStore.careOpsContract.cityBonus"
                  @change="
                    handleCurrencyField(
                      'cityBonus',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    handleCurrencyField(
                      'cityBonus',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.cityBonus')} ${t('Msg_.isRequired')}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-care-allowance-field"
                  icon="mdi-currency-eur"
                  class="pr-3"
                  :label="t('Msg_.careAllowance')"
                  :value="
                    peopleContractCreationStore.careOpsContract.careAllowance
                  "
                  @change="
                    handleCurrencyField(
                      'careAllowance',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    handleCurrencyField(
                      'careAllowance',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.careAllowance')} ${t('Msg_.isRequired')}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-non-dynamic-allowance-field"
                  icon="mdi-currency-eur"
                  class="pl-3"
                  :label="t('Msg_.nonDynamicAllowance')"
                  :value="
                    peopleContractCreationStore.careOpsContract
                      .nonDynamicAllowance
                  "
                  @change="
                    handleCurrencyField(
                      'nonDynamicAllowance',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    handleCurrencyField(
                      'nonDynamicAllowance',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.nonDynamicAllowance')} ${t(
                        'Msg_.isRequired'
                      )}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-individual-allowance-field"
                  icon="mdi-currency-eur"
                  class="pr-3"
                  :label="t('Msg_.individualAllowance')"
                  :value="
                    peopleContractCreationStore.careOpsContract
                      .individualAllowance
                  "
                  @change="
                    handleCurrencyField(
                      'individualAllowance',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    handleCurrencyField(
                      'individualAllowance',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.individualAllowance')} ${t(
                        'Msg_.isRequired'
                      )}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-monthly-base-salary-field"
                  icon="mdi-currency-eur"
                  class="pl-3"
                  :label="t('Msg_.monthlyBaseSalary')"
                  :value="
                    peopleContractCreationStore.careOpsContract
                      .monthlyBaseSalary
                  "
                  @change="
                    handleCurrencyField(
                      'monthlyBaseSalary',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    handleCurrencyField(
                      'monthlyBaseSalary',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.monthlyBaseSalary')} ${t('Msg_.isRequired')}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-thirtheenth-salary-field"
                  icon="mdi-currency-eur"
                  class="pr-3"
                  :label="t('Msg_.13thSalary')"
                  :value="
                    peopleContractCreationStore.careOpsContract.thirteenthSalary
                  "
                  @change="
                    handleCurrencyField(
                      'thirteenthSalary',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    handleCurrencyField(
                      'thirteenthSalary',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.13thSalary')} ${t('Msg_.isRequired')}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-annual-salary-field"
                  icon="mdi-currency-eur"
                  class="pl-3"
                  :label="t('Msg_.annualSalary')"
                  :value="
                    peopleContractCreationStore.careOpsContract.annualSalary
                  "
                  @change="
                    handleCurrencyField(
                      'annualSalary',
                      helpers.convertValueToCurrency($event)
                    )
                  "
                  @focus="
                    handleCurrencyField(
                      'annualSalary',
                      helpers.convertCurrencyToFloat($event)
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.annualSalary')} ${t('Msg_.isRequired')}`,
                      0
                    ),
                    rules.currency(t('Msg_.rules.onlyNumbersAndDecimals')),
                    rules.currencySmallerOrEqualThan(
                      1000000,
                      t('Msg_.rules.maxAmount', { max: '1.000.000,00' })
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DatePicker
                  data-testid="contract-creation-care-ops-contract-info-signing-date-picker"
                  class="pr-3"
                  :label="t('Msg_.signingDate')"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.signingDate
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.signingDate')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-contract-info-assigner-field"
                  class="pl-3"
                  disabled
                  :label="t('Msg_.assigner')"
                  :value="userStore.userInfo.name"
                />
              </v-col>
            </v-row>
            <v-row dense class="my-5">
              <v-col cols="12" class="d-flex flex-column">
                <span class="custom-label mb-4">
                  {{ t('Msg_.contract') }}
                </span>
                <div v-if="controlDisplayInfo === 'buttons'">
                  <v-btn
                    data-testid="contract-creation-care-ops-contract-info-generate-contract-btn"
                    class="main-action-variant-btn mr-4"
                    :disabled="isContractButtonDisabled"
                    :loading="isGeneratingNewContract"
                    @click="generateNewContract()"
                  >
                    {{ t('Action_.buttons.generateContract') }}
                  </v-btn>
                  <v-btn
                    v-if="!isEditing"
                    data-testid="contract-creation-care-ops-contract-info-upload-contract-btn"
                    class="main-action-variant-btn"
                    :disabled="isContractButtonDisabled"
                    @click="triggerFileSelector()"
                  >
                    {{ t('Action_.buttons.uploadContract') }}
                  </v-btn>
                  <input
                    v-show="false"
                    ref="fileSelector"
                    type="file"
                    accept=".pdf"
                    @change="postDocument('uploadedContract', $event)"
                  />
                </div>
                <div v-else-if="controlDisplayInfo === 'uploadedContract'">
                  <FileInput
                    deletable
                    data-testid="contract-creation-care-ops-uploaded-contract-file-input"
                    :label="t('Msg_.uploadedContract')"
                    :value="
                      peopleContractCreationStore.careOpsContract
                        .uploadedContract
                    "
                    @display-document="
                      displayDocument(
                        '/v2/api/employee/downloadFile?url=',
                        peopleContractCreationStore.careOpsContract
                          .uploadedContract.fileUrl
                      )
                    "
                    @delete-document="deleteDocument('uploadedContract')"
                  />
                </div>
                <div v-else-if="controlDisplayInfo === 'generatedContract'">
                  <FileInput
                    deletable
                    data-testid="contract-creation-care-ops-generated-contract-file-input"
                    :label="t('Msg_.generatedContract')"
                    :value="`${peopleContractCreationStore.careOpsContract.generatedContractId}.pdf`"
                    @display-document="
                      displayDocument(
                        '/v2/api/recruitments/documents?path=',
                        documentPath
                      )
                    "
                    @delete-document="clearGeneratedContractData()"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import Combobox from '@/components/shared/customComponents/inputs/combobox-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import SwitchControl from '@/components/shared/customComponents/controls/switch-control.vue';
import FileInput from '@/components/shared/customComponents/inputs/file-input.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '@/components/people/locales/languages';
import { useRoute } from 'vue-router/composables';
import { useUserStore } from '@/store/pinia/user.store.js';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { useProfileStore } from '@/store/pinia/profile.store.js';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed, nextTick, onMounted, onBeforeUnmount } from 'vue';
import { useContractToolsV2 } from '@/helpers/composables/contract-tools-v2.js';
import { useDocumentTools } from '@/helpers/composables/document-tools.js';

const form = ref(null);
const route = useRoute();
const fileSelector = ref();
const userStore = useUserStore();
const peopleStore = usePeopleStore();
const profileStore = useProfileStore();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });
const { retryRequest, displayDocument } = useDocumentTools();
const {
  validateAdditionalTraining,
  totalActiveSwitches,
  updateWeekDay,
  updateSplitHours,
  updateCompensationType,
  prepareWeeklyWorkingHoursToPost,
  formatAdditionalTrainingKeysToPost,
  formatCurrencyFieldsToPost,
  formatPercentageFieldsToPost,
} = useContractToolsV2();

// --- Local variables ---
const open = ref(0);
const contractId = ref(null);
const documentPath = ref(null);
const isGenerateNewContractLoading = ref(false);
const isGettingContractInfo = ref(false);
const currencyKeys = [
  'hourlyWage',
  'cityBonus',
  'careAllowance',
  'nonDynamicAllowance',
  'individualAllowance',
  'monthlyBaseSalary',
  'thirteenthSalary',
  'annualSalary',
];
const percentageKeys = [
  'costCenter1Percentage',
  'costCenter2Percentage',
  'costCenter3Percentage',
];

// --- Methods ---
const handleAdditionaTrainingSelection = (payload) => {
  peopleContractCreationStore.careOpsContract.additionalTraining =
    validateAdditionalTraining(payload);
};

const handleWeeklyWorkingHours = async (index) => {
  updateWeekDay(index, peopleContractCreationStore.careOpsContract);
  peopleContractCreationStore.careOpsContract.vacationDays =
    vacationDaysPerYear.value;
  updateSplitHours(peopleContractCreationStore.careOpsContract);
  await nextTick();
  form.value.validate();
};

const handleCompensationType = () => {
  peopleContractCreationStore.careOpsContract.compensationType =
    updateCompensationType(peopleContractCreationStore.careOpsContract);
};

const handleCurrencyField = (field, value) => {
  peopleContractCreationStore.careOpsContract[field] = value;
};

const handleTotalWeeklyWorkingHours = async () => {
  updateSplitHours(peopleContractCreationStore.careOpsContract);
  handleCompensationType();
  await nextTick();
  form.value.validate();
};

const prepareDataToPost = () => {
  let payload = { ...{}, ...peopleContractCreationStore.careOpsContract };

  payload.weeklyWorkingHours = prepareWeeklyWorkingHoursToPost(
    peopleContractCreationStore.careOpsContract.weeklyWorkingHours
  );

  formatCurrencyFieldsToPost(currencyKeys, payload);

  formatPercentageFieldsToPost(percentageKeys, payload);

  payload.additionalTraining = formatAdditionalTrainingKeysToPost(
    payload.additionalTraining
  );

  payload.yearsOfExperience = parseInt(payload.yearsOfExperience);
  payload.totalWeeklyWorkingDays = totalActiveSwitches(
    peopleContractCreationStore.careOpsContract
  );

  if (!payload.startDatePriorToAcquisition) {
    payload.startDatePriorToAcquisition = null;
  }

  return payload;
};

const checkIfDocumentIsReady = async () => {
  try {
    return await peopleContractCreationStore.getDocumentById(contractId.value);
  } catch (error) {
    console.log(error);
  }
};

const handleContractData = (data) => {
  documentPath.value = data.documentPath;
  displayDocument('/v2/api/recruitments/documents?path=', documentPath.value);
};

const finishTasks = () => {
  peopleContractCreationStore.careOpsContract.generatedContractId =
    contractId.value;
  isGettingContractInfo.value = false;
};

const getDocumentById = async () => {
  try {
    isGettingContractInfo.value = true;
    const downloadIsReady = await retryRequest(checkIfDocumentIsReady, 5000);
    handleContractData(downloadIsReady);
    finishTasks();
  } catch (error) {
    console.log(error);
  }
};

const generateNewContract = async () => {
  let payload = prepareDataToPost();

  if (isGenerateNewContractLoading.value) {
    isGenerateNewContractLoading.value = true;
  }

  try {
    contractId.value = await peopleContractCreationStore.generateNewContract(
      payload
    );
    if (contractId.value) {
      getDocumentById();
      peopleContractCreationStore.careOpsContract.uploadedContract = null;
    }
  } catch (error) {
    console.log(error);
  } finally {
    isGenerateNewContractLoading.value = false;
  }
};

const triggerFileSelector = () => {
  fileSelector.value.click();
};

const clearGeneratedContractData = () => {
  peopleContractCreationStore.careOpsContract.generatedContractId = null;
  documentPath.value = null;
};

const postDocument = (field, value) => {
  let payload = {
    field,
    value: value.target.files[0],
    contract: peopleContractCreationStore.careOpsContract,
  };
  try {
    peopleContractCreationStore.postDocument(payload);
    clearGeneratedContractData();
  } catch (error) {
    console.log(error);
  }
};

const deleteDocument = async (field) => {
  const payload = {
    field,
    contract: peopleContractCreationStore.careOpsContract,
  };

  try {
    await peopleContractCreationStore.deleteDocument(payload);
  } catch (error) {
    console.log(error);
  }
};

const checkFormValidation = () => {
  if (isEditing.value) form.value.validate();
};

// --- Computed ---
const isEditing = computed(() => {
  return !!route.params.employeeId;
});

const selectOriginType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectOriginTypes'),
    peopleStore.peopleResources?.employeeOrigins
  );
});

const selectQualificationType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectQualificationType'),
    peopleStore.peopleResources?.contractCreationQualificationTypes
  );
});

const selectAdditionalTrainingType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectAdditionalTrainingTypes'),
    peopleStore.peopleResources?.additionalTrainingTypes
  );
});

const selectLevelOfEducationType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectLevelOfEducationType'),
    peopleStore.peopleResources?.levelEducationTypes
  );
});

const billingHub = computed(() => {
  const source = profileStore.profileHubs.filter((hub) => {
    return hub.id === peopleContractCreationStore.careOpsContract.mainHub;
  });
  return source[0]?.billingHubName || '';
});

const companyName = computed(() => {
  const source = profileStore.profileHubs.filter((hub) => {
    return hub.id === peopleContractCreationStore.careOpsContract.mainHub;
  });
  return source[0]?.companyName || '';
});

const selectContractType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectContractType'),
    peopleStore.peopleResources?.contractTypes
  );
});

const selectContractPeriodType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectContractPeriodType'),
    peopleStore.peopleResources?.contractPeriodTypes
  );
});

const isContractUnlimited = computed(() => {
  return peopleContractCreationStore.careOpsContract.contractPeriod === 1;
});

const selectProbationType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationType'),
    peopleStore.peopleResources?.probationTypes
  );
});

const selectProbationNoticePeriodType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationNoticePeriodType'),
    peopleStore.peopleResources?.probationNoticePeriodTypes
  );
});

const selectProbationNoticePeriodAfterType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectProbationNoticePeriodAfterTypes'),
    peopleStore.peopleResources?.probationNoticePeriodAfterTypes
  );
});

const selectJobLevelType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectJobLevelTypes'),
    peopleStore.peopleResources?.jobLevelTypes
  );
});

const selectExperienceLevelType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectExperienceLevelTypes'),
    peopleStore.peopleResources?.experienceLevelTypes
  );
});

const selectCompensationTypes = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectCompensationType'),
    peopleStore.peopleResources?.contractCreationCompensationTypes
  );
});

const getCompensationTypeText = computed(() => {
  const compensationTypes = selectCompensationTypes.value.find(
    (type) =>
      type.value ===
      peopleContractCreationStore.careOpsContract.compensationType
  );
  return compensationTypes?.text;
});

const vacationDaysPerYear = computed(() => {
  const totalWorkingDays = totalActiveSwitches(
    peopleContractCreationStore.careOpsContract
  );

  if (
    peopleStore.peopleResources?.vacationDaysCalculations &&
    totalWorkingDays !== 7
  ) {
    const selectedVacationDaysCalculations =
      peopleStore.peopleResources?.vacationDaysCalculations.find(
        (days) => days.weeklyWorkingDays === totalWorkingDays
      );
    return selectedVacationDaysCalculations.yearlyVacationDays;
  }

  return null;
});

const controlDisplayInfo = computed(() => {
  let display = 'buttons';
  if (peopleContractCreationStore.careOpsContract.uploadedContract) {
    display = 'uploadedContract';
  }
  if (peopleContractCreationStore.careOpsContract.generatedContractId) {
    display = 'generatedContract';
  }
  return display;
});

const isGeneratingNewContract = computed(() => {
  return isGenerateNewContractLoading.value || isGettingContractInfo.value;
});

const isFormValid = computed(() => {
  return Object.values(peopleContractCreationStore.formValidations).every(
    (value) => value === true
  );
});

const isContractButtonDisabled = computed(() => {
  return !isFormValid.value || isGeneratingNewContract.value;
});

const isWeeklyWorkingHoursValid = computed(() => {
  let maxHoursValid = null;
  const activeDays = totalActiveSwitches(
    peopleContractCreationStore.careOpsContract
  );

  if (
    peopleContractCreationStore.careOpsContract.totalWeeklyWorkingHours !==
      null &&
    peopleContractCreationStore.careOpsContract.totalWeeklyWorkingHours !==
      '' &&
    activeDays > 0 &&
    peopleContractCreationStore.careOpsContract.totalWeeklyWorkingHours /
      activeDays <=
      24
  ) {
    maxHoursValid = true;
  }

  return maxHoursValid;
});

const isStartDateDisabled = computed(() => {
  // should be disabled if origin !== 2 (Acquisition)
  return peopleContractCreationStore.careOpsContract.origin !== 2;
});

const startDateRules = computed(() => {
  return isStartDateDisabled.value
    ? []
    : [
        rules.fieldRequired(
          `${t('Msg_.startDatePriorToAcquisition')} ${t(
            'Msg_.rules.isRequired'
          )}`
        ),
      ];
});

const exitDateRules = computed(() =>
  isContractUnlimited.value
    ? []
    : [
        rules.fieldRequired(
          `${t('Msg_.exitDate')} ${t('Msg_.rules.isRequired')}`
        ),
      ]
);

// --- Lifecycle hooks ---
onMounted(() => {
  checkFormValidation();
});

onBeforeUnmount(() => {
  peopleContractCreationStore.careOpsContract.weeklyWorkingHours =
    peopleContractCreationStore.getEmptyWeeklyWorkingHours();
});
</script>

<style scoped lang="scss">
.custom-label {
  font-size: 13px;

  .hours-label {
    color: $grey-regular;
  }
}
.element-border {
  border-top: 1px solid $ash-regular;
  border-radius: 4px;
}
</style>
