<template>
  <v-expansion-panels
    data-testid="contract-creation-care-ops-master-info-container"
    class="shadow-regular"
    :value="open"
    flat
  >
    <v-expansion-panel class="rounded-lg pa-2">
      <v-expansion-panel-header>
        <div>
          <span
            data-testid="contract-creation-care-ops-master-info-title"
            class="heading-h3 mr-2"
          >
            {{ t('Title_.subTitle.masterInformation') }}
          </span>
        </div>
        <template #actions>
          <v-icon
            data-testid="contract-creation-care-ops-master-info-chevron-icon"
            color="#212121"
          >
            mdi-chevron-down
          </v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-form
          data-testid="contract-creation-care-ops-master-info-form"
          ref="form"
          class="mt-4"
          v-model="
            peopleContractCreationStore.formValidations.masterInfoValidation
          "
        >
          <v-container class="pa-0">
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-first-name-field"
                  class="pr-3"
                  :label="t('Msg_.firstName')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.firstName
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.firstName')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      50,
                      `${t('Msg_.firstName')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-last-name-field"
                  class="pl-3"
                  :label="t('Msg_.lastName')"
                  v-model="peopleContractCreationStore.careOpsContract.lastName"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.lastName')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      50,
                      `${t('Msg_.lastName')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DropdownInput
                  data-testid="contract-creation-care-ops-master-info-gender-dropdown"
                  class="pr-3"
                  :label="t('Msg_.gender')"
                  :placeholder="t('Msg_.selectOption')"
                  v-model="peopleContractCreationStore.careOpsContract.gender"
                  :items="selectGenderType"
                  :clearable="false"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.gender')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-birth-name-field"
                  class="pl-3"
                  :label="t('Msg_.birthName')"
                  optional
                  v-model="
                    peopleContractCreationStore.careOpsContract.birthName
                  "
                  :rules="[
                    rules.valueSize(
                      50,
                      `${t('Msg_.birthName')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <DatePicker
                  data-testid="contract-creation-care-ops-master-info-date-of-birth-picker"
                  class="pr-3"
                  :label="t('Msg_.dateOfBirth')"
                  :placeholder="t('Msg_.datePlaceholder')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.dateOfBirth
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.dateOfBirth')} ${t('Msg_.rules.isRequired')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-nationality-field"
                  class="pl-3"
                  :label="t('Msg_.nationality')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.nationality
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.nationality')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      50,
                      `${t('Msg_.nationality')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-street-field"
                  class="pr-3"
                  :label="t('Msg_.street')"
                  v-model="peopleContractCreationStore.careOpsContract.street"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.street')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      50,
                      `${t('Msg_.street')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-houser-number-field"
                  class="pl-3"
                  :label="t('Msg_.houseNumber')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.houseNumber
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.houseNumber')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      10,
                      `${t('Msg_.houseNumber')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 10 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-zip-code-field"
                  class="pr-3"
                  :label="t('Msg_.zipCode')"
                  v-model="peopleContractCreationStore.careOpsContract.zipCode"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.zipCode')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      10,
                      `${t('Msg_.zipCode')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 10 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-city-field"
                  class="pl-3"
                  :label="t('Msg_.city')"
                  v-model="peopleContractCreationStore.careOpsContract.city"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.city')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      50,
                      `${t('Msg_.city')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-country-field"
                  class="pr-3"
                  :label="t('Msg_.country')"
                  v-model="peopleContractCreationStore.careOpsContract.country"
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.country')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.valueSize(
                      50,
                      `${t('Msg_.country')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-personal-phone-number-field"
                  class="pr-3"
                  :label="t('Msg_.personalPhoneNumber')"
                  v-model="
                    peopleContractCreationStore.careOpsContract
                      .personalPhoneNumber
                  "
                  :error="phoneNumberValidation.isInUse"
                  :error-messages="
                    phoneNumberValidation.isInUse
                      ? t('Msg_.rules.PhoneNumberInSquirtle')
                      : ''
                  "
                  @change="
                    validatePhoneNumber(
                      peopleContractCreationStore.careOpsContract
                        .personalPhoneNumber
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.personalPhoneNumber')} ${t(
                        'Msg_.rules.isRequired'
                      )}`,
                      rules.valueSize(
                        30,
                        `${t('Msg_.personalPhoneNumber')} ${t(
                          'Msg_.rules.fieldMustHaveLessThan'
                        )} 30 ${t('Msg_.rules.characters')}`
                      )
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-personal-email-field"
                  class="pl-3"
                  :label="t('Msg_.personalEmail')"
                  v-model="
                    peopleContractCreationStore.careOpsContract.personalEmail
                  "
                  :error="emailValidation.isInUse"
                  :error-messages="
                    emailValidation.isInUse
                      ? t('Msg_.rules.emailInSquirtle')
                      : ''
                  "
                  @change="
                    validateEmail(
                      peopleContractCreationStore.careOpsContract.personalEmail
                    )
                  "
                  :rules="[
                    rules.fieldRequired(
                      `${t('Msg_.personalEmail')} ${t('Msg_.rules.isRequired')}`
                    ),
                    rules.email(t('Msg_.rules.invalidEmail')),
                    rules.valueSize(
                      50,
                      `${t('Msg_.personalEmail')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-emergency-contact-field"
                  class="pr-3"
                  :label="t('Msg_.emergencyContact')"
                  optional
                  v-model="
                    peopleContractCreationStore.careOpsContract.emergencyContact
                  "
                  :rules="[
                    rules.valueSize(
                      50,
                      `${t('Msg_.emergencyContact')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 50 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-emergency-contact-phone-field"
                  class="pl-3"
                  :label="t('Msg_.emergencyContactPhone')"
                  optional
                  v-model="
                    peopleContractCreationStore.careOpsContract
                      .emergencyContactPhone
                  "
                  :rules="[
                    rules.valueSize(
                      20,
                      `${t('Msg_.emergencyContactPhone')} ${t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 20 ${t('Msg_.rules.characters')}`
                    ),
                  ]"
                />
              </v-col>
            </v-row>
            <v-row dense class="mb-4">
              <v-col>
                <div class="element-border" />
              </v-col>
            </v-row>
            <v-row dense class="d-flex justify-space-between">
              <v-col cols="6">
                <RegularTextInput
                  data-testid="contract-creation-care-ops-master-info-lbnr-field"
                  class="pr-3"
                  label="LBNR"
                  optional
                  v-model="peopleContractCreationStore.careOpsContract.lbnr"
                  :rules="[
                    rules.totalSize(9, t('Msg_.rules.mustHaveNineDigits')),
                    rules.onlyNumbers(t('Msg_.rules.onlyNumbers')),
                  ]"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DropdownInput from '@/components/shared/customComponents/inputs/dropdown-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';

import rules from '@/helpers/rules';
import helpers from '@/helpers/helpers';
import languages from '@/components/people/locales/languages';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed } from 'vue';

const form = ref();
const peopleStore = usePeopleStore();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t, tm } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const open = ref(0);
const phoneNumberValidation = ref({
  isInUse: false,
});
const emailValidation = ref({
  isInUse: false,
});

// --- Methods ---
const getPhoneNumberValidation = async (phoneNumber) => {
  try {
    phoneNumberValidation.value =
      await peopleContractCreationStore.getPhoneNumberValidation(phoneNumber);
  } catch (error) {
    console.log(error);
  }
};

const getEmailValidation = async (email) => {
  try {
    emailValidation.value =
      await peopleContractCreationStore.getEmailValidation(email);
  } catch (error) {
    console.log(error);
  }
};

const validatePhoneNumber = async (phoneNumber) => {
  if (!phoneNumber || phoneNumber.trim().length === 0) {
    phoneNumberValidation.value.isInUse = false;
  } else {
    await getPhoneNumberValidation(phoneNumber);
  }
};

const validateEmail = async (email) => {
  if (!email || email.trim().length === 0) {
    emailValidation.value.isInUse = false;
  } else {
    await getEmailValidation(email);
  }
};

// --- Computed ---
const selectGenderType = computed(() => {
  return helpers.translateAndAdaptToVuetify(
    tm('Action_.selectGenderTypes'),
    peopleStore.peopleResources?.genders
  );
});
</script>

<style scoped lang="scss">
.element-border {
  border-top: 1px solid $ash-regular;
  border-radius: 4px;
}
</style>
