var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{staticClass:"shadow-regular",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-container","value":_setup.open,"flat":""}},[_c('v-expansion-panel',{staticClass:"rounded-lg pa-2"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"data-testid":"contract-creation-care-ops-payroll-info-chevron-icon","color":"#212121"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}])},[_c('div',[_c('span',{staticClass:"heading-h3 mr-2",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-title"}},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.payrollInformation'))+" ")])])]),_c('v-expansion-panel-content',[_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-form"},model:{value:(
          _setup.peopleContractCreationStore.formValidations.payrollInfoValidation
        ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.formValidations, "payrollInfoValidation", $$v)},expression:"\n          peopleContractCreationStore.formValidations.payrollInfoValidation\n        "}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-tax-id-field","label":_setup.t('Msg_.taxId'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.taxId')} ${_setup.t('Msg_.isRequired')}`
                  ),
                  _setup.rules.onlyNumbers(_setup.t('Msg_.rules.onlyNumbers')),
                  _setup.rules.totalSize(11, _setup.t('Msg_.rules.mustHaveElevenDigits')),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.taxId),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "taxId", $$v)},expression:"peopleContractCreationStore.careOpsContract.taxId"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-tax-class-dropdown","label":_setup.t('Msg_.taxClass'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectTaxClassType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.taxClass')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.taxClass),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "taxClass", $$v)},expression:"peopleContractCreationStore.careOpsContract.taxClass"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-number-of-children-field","label":_setup.t('Msg_.numberOfChildren'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.numberOfChildren')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`,
                    0
                  ),
                  _setup.rules.onlyNumbers(_setup.t('Msg_.rules.onlyNumbers')),
                  _setup.rules.validateNumberInRange(
                    0,
                    99,
                    _setup.t('Msg_.rules.maximumValueExceeded')
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.numberOfChildren
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "numberOfChildren", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.numberOfChildren\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-degree-of-disability-dropdown","label":_setup.t('Msg_.degreeOfDisability'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectDisabilityType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.degreeOfDisability')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .degreeOfDisability
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "degreeOfDisability", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .degreeOfDisability\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-other-job-dropdown","label":_setup.t('Msg_.otherJobCompensationType'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectOtherJobCompensationType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.otherJobCompensationType')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .otherJobCompensationType
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "otherJobCompensationType", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .otherJobCompensationType\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-confession-dropdown","label":_setup.t('Msg_.confession'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectReligionTypes,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.confession')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.confession
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "confession", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.confession\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-health-insurance-type-dropdown","label":_setup.t('Msg_.healthInsuranceType'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectHealthInsuranceType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.healthInsuranceType')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .healthInsuranceType
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "healthInsuranceType", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .healthInsuranceType\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-health-insurance-name-field","label":_setup.t('Msg_.healthInsuranceName'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.healthInsuranceName')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.healthInsuranceName')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .healthInsuranceName
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "healthInsuranceName", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .healthInsuranceName\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-social-security-number-field","label":_setup.t('Msg_.socialSecurityNumber'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.socialSecurityNumber')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`
                  ),
                  _setup.rules.valueSize(
                    30,
                    `${_setup.t('Msg_.socialSecurityNumber')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 30 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .socialSecurityNumber
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "socialSecurityNumber", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .socialSecurityNumber\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-account-owner-field","label":_setup.t('Msg_.accountOwner'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.accountOwner')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.accountOwner')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.accountOwner
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "accountOwner", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.accountOwner\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-payment-method-dropdown","label":_setup.t('Msg_.paymentMethod'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectPaymentType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.paymentMethod')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.paymentMethod
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "paymentMethod", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.paymentMethod\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-iban-number-field","label":_setup.t('Msg_.iban'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.iban')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.iban(_setup.t('Msg_.rules.invalidIban')),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.iban')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.ibanNumber
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "ibanNumber", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.ibanNumber\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-cost-center-1-field","label":_setup.t('Msg_.costCenter1'),"optional":"","rules":[
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.costCenter1')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.costCenter1
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "costCenter1", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.costCenter1\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-cost-center-1-percentage-field","label":_setup.t('Msg_.costCenter1Percentage'),"optional":"","rules":[
                  _setup.rules.numberWithDecimalsOrNull(_setup.t('Msg_.rules.onlyNumbers')),
                  _setup.rules.smallerOrEqualThan(
                    100,
                    _setup.t('Msg_.rules.maximumValueExceeded')
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .costCenter1Percentage
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "costCenter1Percentage", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .costCenter1Percentage\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-cost-center-2-field","label":_setup.t('Msg_.costCenter2'),"optional":"","rules":[
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.costCenter2')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.costCenter2
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "costCenter2", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.costCenter2\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-cost-center-2-percentage-field","label":_setup.t('Msg_.costCenter2Percentage'),"optional":"","rules":[
                  _setup.rules.numberWithDecimalsOrNull(_setup.t('Msg_.rules.onlyNumbers')),
                  _setup.rules.smallerOrEqualThan(
                    100,
                    _setup.t('Msg_.rules.maximumValueExceeded')
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .costCenter2Percentage
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "costCenter2Percentage", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .costCenter2Percentage\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-cost-center-3-field","label":_setup.t('Msg_.costCenter3'),"optional":"","rules":[
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.costCenter3')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.costCenter3
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "costCenter3", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.costCenter3\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-payroll-info-cost-center-3-percentage-field","label":_setup.t('Msg_.costCenter3Percentage'),"optional":"","rules":[
                  _setup.rules.numberWithDecimalsOrNull(_setup.t('Msg_.rules.onlyNumbers')),
                  _setup.rules.smallerOrEqualThan(
                    100,
                    _setup.t('Msg_.rules.maximumValueExceeded')
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .costCenter3Percentage
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "costCenter3Percentage", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .costCenter3Percentage\n                "}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }