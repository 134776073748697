<template>
  <v-container>
    <v-row
      :data-testid="`contract-creation-results-list-item-${index}-container`"
      class="d-flex align-center border-bottom pl-2 py-1"
      :class="listItemClasses(index)"
    >
      <v-col class="d-flex pa-0 mr-3" cols="1">
        <v-icon
          :data-testid="`contract-creation-results-list-item-${index}-chevron-btn`"
          class="mr-3"
          color="blueRegular"
          @click="displayProfile(employee)"
        >
          {{ employee.showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
        </v-icon>
        <v-radio
          :data-testid="`contract-creation-results-list-item-${index}-radio`"
          :value="index"
          color="mineBlack"
        />
      </v-col>
      <v-col cols="3">
        <span
          :data-testid="`contract-creation-results-list-item-${index}-name`"
          class="body-text-4"
        >
          {{ employee.firstName }} {{ employee.lastName }}
        </span>
      </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="5">
        <span
          :data-testid="`contract-creation-results-list-item-${index}-main-hub`"
          class="body-text-4 ml-6"
        >
          {{ getHubNameById(employee.hubsId[0]) }}
        </span>
      </v-col>

      <v-expand-transition>
        <div v-if="employee.showDetails" class="d-flex justify-end flex-grow-1">
          <div
            :data-testid="`contract-creation-results-list-item-${index}-form-container`"
            class="border-top form-container mt-1"
          >
            <div class="d-flex mt-4 mr-4">
              <RegularTextInput
                :data-testid="`contract-creation-results-list-item-${index}-first-name-field`"
                class="flex-grow-1"
                :label="t('Msg_.firstName')"
                :value="employee.firstName"
                disabled
              />
              <div class="mx-4" />
              <RegularTextInput
                :data-testid="`contract-creation-results-list-item-${index}-last-name-field`"
                class="flex-grow-1"
                :label="t('Msg_.lastName')"
                :value="employee.lastName"
                disabled
              />
            </div>
            <div class="d-flex mr-4">
              <DatePicker
                :data-testid="`contract-creation-results-list-item-${index}-start-date-picker`"
                class="flex-grow-1"
                :label="t('Msg_.startDate')"
                :value="employee.startDate"
                disabled
              />
              <div class="mx-4" />
              <DatePicker
                :data-testid="`contract-creation-results-list-item-${index}-date-of-birth-picker`"
                class="flex-grow-1"
                :label="t('Msg_.dateOfBirth')"
                :value="employee.dateOfBirth"
                disabled
              />
            </div>
            <div class="d-flex mr-4">
              <RegularTextInput
                :data-testid="`contract-creation-results-list-item-${index}-main-hub-field`"
                class="flex-grow-1"
                :label="t('Msg_.mainHub')"
                :value="getHubNameById(employee.hubsId[0])"
                disabled
              />
              <div class="mx-4" />
              <RegularTextInput
                :data-testid="`contract-creation-results-list-item-${index}-working-hours-field`"
                class="flex-grow-1"
                :label="t('Msg_.weeklyWorkingHours')"
                :value="employee.totalWeeklyWorkingHours"
                disabled
              />
            </div>
          </div>
        </div>
      </v-expand-transition>
    </v-row>
  </v-container>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import DatePicker from '@/components/shared/customComponents/inputs/date-picker.vue';
import languages from '@/components/people/locales/languages';
import { usePeopleStore } from '@/store/pinia/people.store.js';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { defineProps } from 'vue';

const peopleStore = usePeopleStore();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  employee: {
    type: Object,
    required: true,
  },
  index: {
    type: Number,
    required: true,
  },
  selectedEmployee: {
    type: null,
    required: true,
  },
});

// --- Methods ---
const toggleProfileDetails = (employee, value) => {
  employee.showDetails = value;
};

const collapseProfileDetails = () => {
  peopleContractCreationStore.recruitments.noventiEmployees
    .filter((_, index) => index !== props.index)
    .forEach((employee) => toggleProfileDetails(employee, false));
};

const displayProfile = (employee) => {
  collapseProfileDetails();
  toggleProfileDetails(employee, !employee.showDetails);
};

const listItemClasses = (index) => {
  const bgColor = props.selectedEmployee === index ? 'ashLight' : '';
  const elementBorder =
    index === 0 ? 'border-top border-bottom' : 'border-bottom';
  return `${bgColor} ${elementBorder}`;
};

const getHubNameById = (id) => {
  const hubObj = peopleStore.peopleHubList.find((hub) => hub.id === id);
  return hubObj?.name;
};
</script>

<style lang="scss" scoped>
.border-top {
  border-top: 1px solid $ash-regular;
}

.border-bottom {
  border-bottom: 1px solid $ash-regular;
}

.form-container {
  width: 592px;
}
</style>
