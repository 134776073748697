var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{staticClass:"shadow-regular",attrs:{"data-testid":"contract-creation-care-ops-master-info-container","value":_setup.open,"flat":""}},[_c('v-expansion-panel',{staticClass:"rounded-lg pa-2"},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"data-testid":"contract-creation-care-ops-master-info-chevron-icon","color":"#212121"}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}])},[_c('div',[_c('span',{staticClass:"heading-h3 mr-2",attrs:{"data-testid":"contract-creation-care-ops-master-info-title"}},[_vm._v(" "+_vm._s(_setup.t('Title_.subTitle.masterInformation'))+" ")])])]),_c('v-expansion-panel-content',[_c('v-form',{ref:"form",staticClass:"mt-4",attrs:{"data-testid":"contract-creation-care-ops-master-info-form"},model:{value:(
          _setup.peopleContractCreationStore.formValidations.masterInfoValidation
        ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.formValidations, "masterInfoValidation", $$v)},expression:"\n          peopleContractCreationStore.formValidations.masterInfoValidation\n        "}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-first-name-field","label":_setup.t('Msg_.firstName'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.firstName')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.firstName')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.firstName
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "firstName", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.firstName\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-last-name-field","label":_setup.t('Msg_.lastName'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.lastName')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.lastName')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.lastName),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "lastName", $$v)},expression:"peopleContractCreationStore.careOpsContract.lastName"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DropdownInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-gender-dropdown","label":_setup.t('Msg_.gender'),"placeholder":_setup.t('Msg_.selectOption'),"items":_setup.selectGenderType,"clearable":false,"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.gender')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.gender),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "gender", $$v)},expression:"peopleContractCreationStore.careOpsContract.gender"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-birth-name-field","label":_setup.t('Msg_.birthName'),"optional":"","rules":[
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.birthName')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.birthName
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "birthName", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.birthName\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.DatePicker,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-date-of-birth-picker","label":_setup.t('Msg_.dateOfBirth'),"placeholder":_setup.t('Msg_.datePlaceholder'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.dateOfBirth')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.dateOfBirth
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "dateOfBirth", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.dateOfBirth\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-nationality-field","label":_setup.t('Msg_.nationality'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.nationality')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.nationality')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.nationality
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "nationality", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.nationality\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-street-field","label":_setup.t('Msg_.street'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.street')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.street')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.street),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "street", $$v)},expression:"peopleContractCreationStore.careOpsContract.street"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-houser-number-field","label":_setup.t('Msg_.houseNumber'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.houseNumber')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    10,
                    `${_setup.t('Msg_.houseNumber')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 10 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.houseNumber
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "houseNumber", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.houseNumber\n                "}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-zip-code-field","label":_setup.t('Msg_.zipCode'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.zipCode')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    10,
                    `${_setup.t('Msg_.zipCode')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 10 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.zipCode),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "zipCode", $$v)},expression:"peopleContractCreationStore.careOpsContract.zipCode"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-city-field","label":_setup.t('Msg_.city'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.city')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.city')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.city),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "city", $$v)},expression:"peopleContractCreationStore.careOpsContract.city"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-country-field","label":_setup.t('Msg_.country'),"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.country')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.country')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.country),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "country", $$v)},expression:"peopleContractCreationStore.careOpsContract.country"}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-personal-phone-number-field","label":_setup.t('Msg_.personalPhoneNumber'),"error":_setup.phoneNumberValidation.isInUse,"error-messages":_setup.phoneNumberValidation.isInUse
                    ? _setup.t('Msg_.rules.PhoneNumberInSquirtle')
                    : '',"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.personalPhoneNumber')} ${_setup.t(
                      'Msg_.rules.isRequired'
                    )}`,
                    _setup.rules.valueSize(
                      30,
                      `${_setup.t('Msg_.personalPhoneNumber')} ${_setup.t(
                        'Msg_.rules.fieldMustHaveLessThan'
                      )} 30 ${_setup.t('Msg_.rules.characters')}`
                    )
                  ),
                ]},on:{"change":function($event){return _setup.validatePhoneNumber(
                    _setup.peopleContractCreationStore.careOpsContract
                      .personalPhoneNumber
                  )}},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .personalPhoneNumber
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "personalPhoneNumber", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .personalPhoneNumber\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-personal-email-field","label":_setup.t('Msg_.personalEmail'),"error":_setup.emailValidation.isInUse,"error-messages":_setup.emailValidation.isInUse
                    ? _setup.t('Msg_.rules.emailInSquirtle')
                    : '',"rules":[
                  _setup.rules.fieldRequired(
                    `${_setup.t('Msg_.personalEmail')} ${_setup.t('Msg_.rules.isRequired')}`
                  ),
                  _setup.rules.email(_setup.t('Msg_.rules.invalidEmail')),
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.personalEmail')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},on:{"change":function($event){return _setup.validateEmail(
                    _setup.peopleContractCreationStore.careOpsContract.personalEmail
                  )}},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.personalEmail
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "personalEmail", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.personalEmail\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-emergency-contact-field","label":_setup.t('Msg_.emergencyContact'),"optional":"","rules":[
                  _setup.rules.valueSize(
                    50,
                    `${_setup.t('Msg_.emergencyContact')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 50 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract.emergencyContact
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "emergencyContact", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract.emergencyContact\n                "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pl-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-emergency-contact-phone-field","label":_setup.t('Msg_.emergencyContactPhone'),"optional":"","rules":[
                  _setup.rules.valueSize(
                    20,
                    `${_setup.t('Msg_.emergencyContactPhone')} ${_setup.t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 20 ${_setup.t('Msg_.rules.characters')}`
                  ),
                ]},model:{value:(
                  _setup.peopleContractCreationStore.careOpsContract
                    .emergencyContactPhone
                ),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract
                    , "emergencyContactPhone", $$v)},expression:"\n                  peopleContractCreationStore.careOpsContract\n                    .emergencyContactPhone\n                "}})],1)],1),_c('v-row',{staticClass:"mb-4",attrs:{"dense":""}},[_c('v-col',[_c('div',{staticClass:"element-border"})])],1),_c('v-row',{staticClass:"d-flex justify-space-between",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c(_setup.RegularTextInput,{staticClass:"pr-3",attrs:{"data-testid":"contract-creation-care-ops-master-info-lbnr-field","label":"LBNR","optional":"","rules":[
                  _setup.rules.totalSize(9, _setup.t('Msg_.rules.mustHaveNineDigits')),
                  _setup.rules.onlyNumbers(_setup.t('Msg_.rules.onlyNumbers')),
                ]},model:{value:(_setup.peopleContractCreationStore.careOpsContract.lbnr),callback:function ($$v) {_vm.$set(_setup.peopleContractCreationStore.careOpsContract, "lbnr", $$v)},expression:"peopleContractCreationStore.careOpsContract.lbnr"}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }