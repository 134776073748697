<template>
  <div>
    <v-card
      data-testid="contract-creation-results-container"
      class="shadow-regular rounded-lg"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <h4
                data-testid="contract-creation-results-title"
                class="heading-h4 mb-1 mineBlack--text"
              >
                {{ t('Msg_.connectExistingNoventiAccount.title') }}
              </h4>
            </v-col>
          </v-row>
          <v-row dense class="mb-3">
            <v-col class="pt-0">
              <span
                data-testid="contract-creation-results-sub-title"
                class="body-text-2 mineBlack--text"
              >
                {{ t('Msg_.resultsSelection.message') }}
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="pt-0">
              <span
                data-testid="contract-creation-results-note"
                class="caption-2 mineBlack--text"
              >
                {{ t('Msg_.resultsSelection.note') }}
              </span>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row
            data-testid="contract-creation-results-header-container"
            class="uppercase-header pb-1 pt-2 px-2"
          >
            <v-col cols="1" class="mr-6"></v-col>
            <v-col cols="3">
              <span data-testid="contract-creation-results-name-header">
                {{ t('Msg_.name') }}
              </span>
            </v-col>
            <v-col cols="2" class="mr-4"></v-col>
            <v-col cols="2">
              <span data-testid="contract-creation-results-main-hub-header">
                {{ t('Msg_.mainHub') }}
              </span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-radio-group
                v-if="peopleContractCreationStore.recruitments.noventiEmployees"
                row
                v-model="selectedEmployee"
                class="mt-0"
                hide-details
              >
                <ContractCreationResultsListItem
                  v-for="(employee, index) in peopleContractCreationStore
                    .recruitments.noventiEmployees"
                  :key="index"
                  :selected-employee="selectedEmployee"
                  :employee="employee"
                  :index="index"
                />
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="ml-2">
        <v-container>
          <v-row class="d-flex justify-end">
            <v-col cols="auto">
              <v-btn
                data-testid="contract-creation-results-connect-account-btn"
                class="main-action-btn mb-2"
                :disabled="disableConnectToNoventiBtn"
                @click="connectExistingNoventiAccount()"
              >
                {{ t('Action_.buttons.connectNoventiAccount') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <v-container class="mt-4">
      <v-row class="d-flex justify-end">
        <v-col cols="auto" class="px-0">
          <v-btn
            data-testid="contract-creation-results-modify-search-btn"
            class="main-action-variant-transparent-btn mr-4"
            @click="routeTo('search')"
          >
            {{ t('Action_.buttons.modifySearchCriteria') }}
          </v-btn>
          <v-btn
            data-testid="contract-creation-results-create-new-btn"
            class="action-btn"
            @click="routeTo('careOpsForm')"
          >
            {{ t('Action_.buttons.createNewNoventiAccount') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import ContractCreationResultsListItem from './contract-creation-results-list-item.vue';
import languages from '@/components/people/locales/languages';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, computed } from 'vue';

const peopleContractCreationStore = usePeopleContractCreationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const selectedEmployee = ref(null);

// --- Methods ---
const assignDataFromNoventi = () => {
  const selectedEmployeeData =
    peopleContractCreationStore.recruitments.noventiEmployees[
      selectedEmployee.value
    ];
  const careOpsContract = peopleContractCreationStore.careOpsContract;

  careOpsContract.firstName = selectedEmployeeData.firstName;
  careOpsContract.lastName = selectedEmployeeData.lastName;
  careOpsContract.startDate = selectedEmployeeData.startDate;
  careOpsContract.dateOfBirth = selectedEmployeeData.dateOfBirth;
  careOpsContract.mainHub = selectedEmployeeData.hubsId[0];
  careOpsContract.totalWeeklyWorkingHours =
    selectedEmployeeData.totalWeeklyWorkingHours;
};

const routeTo = (screen) => {
  peopleContractCreationStore.currentScreen = screen;
  scrollUp();
};

const scrollUp = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

const connectExistingNoventiAccount = () => {
  assignDataFromNoventi();
  routeTo('careOpsForm');
};

// --- Computed ---
const disableConnectToNoventiBtn = computed(() => {
  return !selectedEmployee.value && selectedEmployee.value !== 0;
});
</script>

<style lang="scss" scoped>
.uppercase-header {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: $grey-dark;
}
</style>
