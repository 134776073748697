<template>
  <v-dialog
    data-testid="contract-creation-turn-into-employee-dialog"
    :value="true"
    :retain-focus="false"
    persistent
    width="750"
  >
    <v-card class="py-6 px-4">
      <v-card-title
        data-testid="contract-creation-turn-into-employee-title"
        class="heading-h3 mb-4"
      >
        {{ handleTitle }}
      </v-card-title>
      <v-card-text class="mineBlack--text">
        <span
          data-testid="contract-creation-turn-into-employee-message"
          class="body-text-4"
        >
          {{ t('Action_.dialog.turnIntoEmployeeDialog.body') }}
        </span>
        <ul class="mt-6 pl-4 body-text-3">
          <li
            data-testid="contract-creation-turn-into-employee-squirtle-message"
          >
            {{ t('Action_.dialog.turnIntoEmployeeDialog.squirtle') }}
          </li>
          <li
            data-testid="contract-creation-turn-into-employee-taxmaro-message"
          >
            {{ t('Action_.dialog.turnIntoEmployeeDialog.taxmaro') }}
          </li>
          <li
            data-testid="contract-creation-turn-into-employee-noventi-message"
          >
            {{ t('Action_.dialog.turnIntoEmployeeDialog.noventi') }}
          </li>
        </ul>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          data-testid="contract-creation-turn-into-employee-cancel-btn"
          class="main-action-variant-btn"
          @click="toggleTurnIntoEmployeeDialog()"
        >
          {{ t('Action_.buttons.cancel') }}
        </v-btn>
        <v-btn
          data-testid="contract-creation-turn-into-employee-create-employee-btn"
          class="main-action-btn ml-2"
          @click="createEmployee()"
        >
          {{ t('Action_.buttons.createEmployee') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import languages from '../../../locales/languages';
import { computed, defineEmits, defineProps } from 'vue';
import { useI18n } from 'vue-i18n-bridge';

const emit = defineEmits([
  'create-employee',
  'toggle-turn-into-employee-dialog',
]);
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Props ---
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  contract: {
    type: Object,
    default: () => {},
    required: true,
  },
});

// --- Methods ---
const toggleTurnIntoEmployeeDialog = () => {
  emit('toggle-turn-into-employee-dialog');
};

const createEmployee = () => {
  emit('create-employee');
};

// --- Computed ---
const employeeName = computed(() => {
  return `${props.contract.firstName} ${props.contract.lastName}`;
});

const handleTitle = computed(() => {
  return `${t('Action_.dialog.turnIntoEmployeeDialog.title1')} ${
    employeeName.value
  } ${t('Action_.dialog.turnIntoEmployeeDialog.title2')}`;
});
</script>

<style lang="scss" scoped></style>
