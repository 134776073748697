<template>
  <v-card
    data-testid="contract-creation-search-container"
    class="shadow-regular rounded-lg"
  >
    <v-card-text class="pb-0">
      <v-container>
        <v-row>
          <v-col>
            <h4
              data-testid="contract-creation-search-title"
              class="heading-h4 mineBlack--text"
            >
              {{ t('Msg_.connectExistingNoventiAccount.title') }}
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <span
              data-testid="contract-creation-search-details"
              class="caption-2 mineBlack--text"
            >
              {{ t('Msg_.connectExistingNoventiAccount.details') }}
            </span>
          </v-col>
        </v-row>
      </v-container>
      <v-form
        data-testid="contract-creation-search-form"
        ref="form"
        v-model="isFormValid"
      >
        <v-container class="mt-2">
          <v-row>
            <v-col cols="6">
              <RegularTextInput
                data-testid="contract-creation-search-first-name-field"
                :label="t('Msg_.firstName') + '*'"
                v-model="peopleContractCreationStore.careOpsContract.firstName"
                :rules="[
                  rules.fieldRequired(
                    `${t('Msg_.firstName')} ${t('Msg_.isRequired')}`
                  ),
                  rules.valueSize(
                    100,
                    `${t('Msg_.firstName')} ${t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 100 ${t('Msg_.rules.characters')}`
                  ),
                ]"
              />
            </v-col>
            <v-col cols="6">
              <RegularTextInput
                data-testid="contract-creation-search-first-name-field"
                :label="t('Msg_.lastName') + '*'"
                v-model="peopleContractCreationStore.careOpsContract.lastName"
                :rules="[
                  rules.fieldRequired(
                    `${t('Msg_.lastName')} ${t('Msg_.isRequired')}`
                  ),
                  rules.valueSize(
                    100,
                    `${t('Msg_.lastName')} ${t(
                      'Msg_.rules.fieldMustHaveLessThan'
                    )} 100 ${t('Msg_.rules.characters')}`
                  ),
                ]"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row class="d-flex justify-end">
          <v-col cols="auto">
            <v-btn
              data-testid="contract-creation-search-start-search-btn"
              class="main-action-btn mr-2 mb-2"
              :disabled="!isFormValid"
              @click="getRecruitments()"
            >
              {{ t('Action_.buttons.startSearch') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script setup>
import RegularTextInput from '@/components/shared/customComponents/inputs/regular-text-input.vue';
import languages from '@/components/people/locales/languages';
import rules from '@/helpers/rules';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { ref, onMounted } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const form = ref();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Local variables ---
const isFormValid = ref(false);

// --- Methods ---
const getRecruitments = async () => {
  const urlPayload = {
    firstName: peopleContractCreationStore.careOpsContract.firstName,
    lastName: peopleContractCreationStore.careOpsContract.lastName,
  };
  await peopleContractCreationStore.getRecruitments(urlPayload);
  redirectUser();
};

const redirectUser = () => {
  if (peopleContractCreationStore.recruitments?.noventiEmployees) {
    routeTo('results');
    scrollUp();
  } else {
    routeTo('noResults');
    scrollUp();
  }
};

const routeTo = (screen) => {
  peopleContractCreationStore.currentScreen = screen;
};

const scrollUp = () => {
  window.scrollTo(0, 0);
};

// --- Lifecycle hooks ---
onMounted(() => {
  trackPage();
});
</script>

<style lang="scss" scoped></style>
