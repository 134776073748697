<template>
  <div>
    <v-card
      data-testid="contract-creation-no-results-container"
      class="shadow-regular rounded-lg"
    >
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <h4
                data-testid="contract-creation-no-results-title"
                class="heading-h4 mineBlack--text"
              >
                {{ t('Msg_.noResultsInNoventi.title') }}
              </h4>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <span
                data-testid="contract-creation-no-results-details"
                class="caption-2 mineBlack--text"
              >
                {{ t('Msg_.noResultsInNoventi.details') }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-container class="mt-4">
      <v-row class="d-flex justify-end">
        <v-col cols="auto" class="px-0">
          <v-btn
            data-testid="contract-creation-no-results-modify-search-btn"
            class="main-action-variant-transparent-btn mr-4"
            @click="routeTo('search')"
          >
            {{ t('Action_.buttons.modifySearchCriteria') }}
          </v-btn>
          <v-btn
            data-testid="contract-creation-no-results-create-new-btn"
            class="action-btn"
            @click="routeTo('careOpsForm')"
          >
            {{ t('Action_.buttons.createNewNoventiAccount') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import languages from '@/components/people/locales/languages';
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import { useI18n } from 'vue-i18n-bridge';
import { onMounted } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const routeTo = (screen) => {
  peopleContractCreationStore.currentScreen = screen;
};

// --- Lifecycle hooks ---
onMounted(() => {
  trackPage();
});
</script>

<style lang="scss" scoped></style>
