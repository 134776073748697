<template>
  <v-card
    data-testid="contract-creation-type-selection-container"
    class="shadow-regular rounded-lg px-2"
  >
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <h4
              data-testid="contract-creation-type-selection-title"
              class="heading-h4 mineBlack--text"
            >
              {{ t('Msg_.chooseProfileType') }}
            </h4>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-radio-group
              class="mt-6"
              v-model="peopleContractCreationStore.isHeadquarterEmployee"
            >
              <v-radio
                data-testid="contract-creation-type-selection-headquarter-radio"
                :value="true"
              >
                <template #label>
                  <div
                    data-testid="contract-creation-type-selection-headquarter-label"
                    class="option-title body-text-2"
                  >
                    {{ t('Msg_.headquarterEmployeeTypeSelection.title') }}
                  </div>
                </template>
              </v-radio>
              <div
                data-testid="contract-creation-type-selection-headquarter-details"
                class="ml-8 body-text-4 process-details-text"
              >
                <span>{{
                  t('Msg_.headquarterEmployeeTypeSelection.groupEmployeeTypes')
                }}</span>
                <ul>
                  <li>
                    {{
                      t('Msg_.headquarterEmployeeTypeSelection.processDetails1')
                    }}
                  </li>
                  <li>
                    {{
                      t('Msg_.headquarterEmployeeTypeSelection.processDetails2')
                    }}
                  </li>
                </ul>
              </div>
              <v-radio
                data-testid="contract-creation-type-selection-care-ops-radio"
                :value="false"
                class="mt-6"
              >
                <template #label>
                  <div
                    data-testid="contract-creation-type-selection-care-ops-label"
                    class="option-title body-text-2"
                  >
                    {{ t('Msg_.careOpsEmployeeTypeSelection.title') }}
                  </div>
                </template>
              </v-radio>
              <div
                data-testid="contract-creation-type-selection-care-ops-details"
                class="ml-8 body-text-4 process-details-text"
              >
                <span>{{
                  t('Msg_.careOpsEmployeeTypeSelection.groupEmployeeTypes')
                }}</span>
                <ul>
                  <li>
                    {{ t('Msg_.careOpsEmployeeTypeSelection.processDetails1') }}
                  </li>
                  <li>
                    {{ t('Msg_.careOpsEmployeeTypeSelection.processDetails2') }}
                  </li>
                </ul>
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-col class="d-flex justify-end">
            <v-btn
              data-testid="contract-creation-type-selection-continue-btn"
              class="main-action-btn ml-2"
              @click="redirectUser()"
            >
              {{ t('Action_.buttons.continue') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { usePeopleContractCreationStore } from '@/store/pinia/people-contract-creation.store.js';
import languages from '@/components/people/locales/languages';
import { useI18n } from 'vue-i18n-bridge';
import { onMounted } from 'vue';
import { amplitudeComposable } from '@/helpers/composables/amplitude.js';

const { trackPage } = amplitudeComposable();
const peopleContractCreationStore = usePeopleContractCreationStore();
const { t } = useI18n({ locale: 'en', messages: languages });

// --- Methods ---
const redirectUser = () => {
  if (peopleContractCreationStore.isHeadquarterEmployee) {
    routeTo('headquarterForm');
  } else {
    routeTo('search');
  }
  scrollUp();
};

const routeTo = (screen) => {
  peopleContractCreationStore.currentScreen = screen;
};

const scrollUp = () => {
  window.scrollTo(0, 0);
};

// --- Lifecycle hooks ---
onMounted(() => {
  trackPage();
});
</script>

<style lang="scss" scoped>
.option-title {
  color: #212121 !important;
}

.process-details-text {
  color: #666666 !important;
}
</style>
